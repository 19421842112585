import React from 'react';
import {Card, Div, RichCell, Text} from '@vkontakte/vkui';
import {AppSettingsContext} from '../app-settings-context';

class CollectionListItem extends React.Component {
    static contextType = AppSettingsContext;

    doResizeImageUrl(image, x, y){
        return image.substring(0, image.lastIndexOf('.')) + '-' + x + 'x' + y + image.substring(image.lastIndexOf('.'));
    }

    declOfNum(number, titles) {
        var cases = [2, 0, 1, 1, 1, 2];
        return titles[ (number%100>4 && number%100<20)? 2 : cases[(number%10<5)?number%10:5] ];
    }

    render() {
        var imageWidth = Math.round((document.documentElement.clientWidth || document.body.clientWidth || window.innerWidth) / 5);
        var imageHeight = Math.round(imageWidth / 2 * 3);
        var borderRadius = Math.round(imageWidth / 20);
        var reviewCount = this.props.data.reviewList ? this.props.data.reviewList.length : 0
        
        return (
            <Card size="l" mode="shadow">
                <RichCell
                    multiline
                    before={<Div><img style={{ maxWidth: imageWidth, maxHeight: imageWidth*2, borderRadius: borderRadius }} src={this.context.domain + "/files" + this.doResizeImageUrl(this.props.data.imageUrl, imageWidth, imageHeight)} alt="" /></Div>}
                    text={<Text weight="regular" style={{ color: "gray"}}>{reviewCount} {this.declOfNum(reviewCount, ['книга', 'книги', 'книг'])}</Text>}
                    caption={<Text weight="regular" style={{ color: "var(--text_secondary)", fontSize: 13, marginTop: 5}}>создано {this.props.data.addedDateTimeStr}<br/>{this.props.data.tagList}</Text>}
                    onClick={() => this.props.showCollection(this.props.data.id)}
                    >
                    <b>{this.props.data.name}</b>
                </RichCell>
            </Card>
        );
    }
  }

  export default CollectionListItem;