import React from 'react';
import {
    Avatar,
    Button, CardGrid,
    Div,
    File,
    FixedLayout,
    FormLayout,
    FormLayoutGroup, Group,
    Header,
    Input,
    Panel,
    PanelHeader,
    PanelHeaderButton, Placeholder, ScreenSpinner, Search,
    SimpleCell,
    Snackbar,
    Textarea
} from '@vkontakte/vkui';
import Icon24BrowserBack from '@vkontakte/icons/dist/24/browser_back';
import Icon24Camera from '@vkontakte/icons/dist/24/camera';
import {AppSettingsContext} from '../app-settings-context';
import Icon16Done from '@vkontakte/icons/dist/16/done';
import Icon16CancelCircleOutline from '@vkontakte/icons/dist/16/cancel_circle_outline';
import Icon56InboxOutline from '@vkontakte/icons/dist/56/inbox_outline';
import Icon56ServicesOutline from '@vkontakte/icons/dist/56/services_outline';
import Icon24Add from '@vkontakte/icons/dist/24/add';
import BookInClubListItem from "../components/BookInClubListItem";

class ClubEdit extends React.Component {
    static contextType = AppSettingsContext;

	constructor(props) {
        super(props);

        this.state ={
            id: props.route.params.id,
            imageUrl: "/club.png",
            isLocalImage: false,
            name: null,
            description: null,
            addedDateTimeStr: null,
            canEdit: false,
            creatorFio: "",
            bookList: null,
            search: "",
            snackbar: null,
        }

        this.getClub = this.getClub.bind(this);
        this.saveClub = this.saveClub.bind(this);
        this.onSearchChange = this.onSearchChange.bind(this);
        this.showSnackBar = this.showSnackBar.bind(this);
        this.onImageChoose = this.onImageChoose.bind(this);
        this.descriptionChange = this.descriptionChange.bind(this);
	}

    componentDidMount() {
        this.getClub();
    }

    getClub() {
        if (this.state.id){
            fetch(this.context.domain + "/api/club?id=" + this.state.id + "&" + window.location.search.substr(1))
            .then((res) => res.json())
            .then(
                (result) => {
                    if (result.status === "SUCCESS"){
                        if (result.data){
                            if (result.data.canEdit) {
                                this.setState({
                                    id: result.data.id,
                                    name: result.data.name,
                                    description: result.data.description,
                                    imageUrl: result.data.imageUrl,
                                    isLocalImage: false,
                                    addedDateTimeStr: result.data.addedDateTimeStr,
                                    canEdit: result.data.canEdit,
                                    creatorFio: result.data.creator.fio,
                                    bookList: result.data.bookList,
                                });
                            } else {
                                this.props.functions.goToPage('clubs.view', {id: result.data.id})
                            }
                        } else {
                            this.setState({
                                id: 0,
                            });
                        }
                    } else {
                        this.showSnackBar("Ошибка: " + result.message, true);
                    }
                    this.props.functions.changePopout(null);
                },
                (error) => {
                    /*TODO: Тут нужна обработка ошибки */
                    this.props.functions.changePopout(null);
                    this.showSnackBar("Ошибка сервера. Попробуйте перезапустить приложение", true);
                }
            ); 
        } else {
            this.props.functions.changePopout(null);
        }
    }

    setObjectValue(object, fieldName, value){
        if (fieldName.includes('.')){ // Если это подобъект
            var innerObjectName = fieldName.split('.')[0];
            var otherNames = fieldName.substr(fieldName.indexOf('.') + 1, fieldName.length);
            var innerObject;
            if (object[innerObjectName]){
                innerObject = object[innerObjectName]
            } else {
                innerObject = {};
            }
            object[innerObjectName] = this.setObjectValue(innerObject, otherNames, value);
        } else { // Если это просто поле
            object[fieldName] = value;
        }
        return object;
    }

    saveClub() {
        const formData = new FormData();

        // Соберем поля с name в json
        var item = {};
        var element_list = document.querySelectorAll('#club-form [name]');
        var element_array = [...element_list];
        element_array.forEach(element => {
            var elName = element.getAttribute('name');
            this.setObjectValue(item, elName, element.value)
        });

        item.bookList = []
        if (this.state.bookList) {
            this.state.bookList.forEach((book) => {
                item.bookList.push({id: book.id})
            })
        }

        formData.append('clubJson', JSON.stringify(item));
        if (this.state.isLocalImage){
            formData.append('image', this.state.imageUrl); // fileField.files[0]
        }

        this.props.functions.changePopout(<ScreenSpinner />);
        fetch(this.context.domain + "/api/club?save&" + window.location.search.substr(1), {
            method: 'POST',
            body: formData
        }).then((res) => res.json())
        .then(
            (result) => {
                if (result.status === "SUCCESS"){
                    if (result.data){
                        this.props.functions.goToPage('clubs.view', {id: result.data.id})
                    } else {
                        this.showSnackBar("Ошибка: " + result.message, true);
                    }
                } else {
                    this.showSnackBar("Ошибка: " + result.message, true);
                }
                this.props.functions.changePopout(null);
            },
            (error) => {
                /*TODO: Тут нужна обработка ошибки */
                console.error('Ошибка:', error);
                this.props.functions.changePopout(null);
            }
        );
    }

    showSnackBar (message, isError = false) {
        if (this.state.snackbar) return;
        this.setState({ snackbar:
          <Snackbar
            layout="vertical"
            onClose={() => this.setState({ snackbar: null })}
            before={isError ? <Avatar size={24} style={{backgroundColor: 'var(--dynamic_red)'}}><Icon16CancelCircleOutline fill="#fff" width={14} height={14} /></Avatar>
                        :<Avatar size={24} style={{backgroundColor: 'var(--accent)'}}><Icon16Done fill="#fff" width={14} height={14} /></Avatar>}
          >
            {message}
          </Snackbar>
        });
    }

    doResizeImageUrl(image, x, y){
        return image.substring(0, image.lastIndexOf('.')) + '-' + x + 'x' + y + image.substring(image.lastIndexOf('.'));
    }

    onImageChoose(event){
        var that = this;
        var element = event.target;
        var files = element.files;
        for (var i = 0, f; f = files[i]; i++) {   
            var reader = new FileReader();
            reader.onload = (function (f) {
                return function (e) {
                    that.props.functions.showPhotoEdit(
                        e.target.result
                        , (imageUrl)=>{
                                if (imageUrl){
                                    that.setState({imageUrl: imageUrl, isLocalImage: true,})
                                }
                            })
                };
            })(f);
            reader.readAsDataURL(f);
        }
    }

    descriptionChange(event){
        this.setState({description: event.target.value});
    }

    onSearchChange(e){
        this.setState({ search: e.target.value });
    }

	render() {
        var imageWidth = Math.round((document.documentElement.clientWidth || document.body.clientWidth || window.innerWidth) / 5);
        var imageHeight = imageWidth;
        var borderRadius = Math.round(imageWidth / 20);

        let items = null;
        var booksCount = this.state.bookList ? this.state.bookList.length : 0
        if (booksCount){
            const search = this.state.search.toLowerCase();
            items = this.state.bookList
                .filter((item) =>
                    (
                        (item.book.name && item.book.name.toLowerCase().indexOf(search) > -1)
                        || (item.book.authorsString && item.book.authorsString.toLowerCase().indexOf(search) > -1)
                    )
                )
                .map((item) => {
                    return (
                        <BookInClubListItem key={item.id} data={item} onClick={() => this.props.functions.goToPage('clubs.book_in_club', {club_id: this.state.id, book_in_club_id: item.id})}/>
                    );
                });

            if (!items || !items.length){
                items = (
                    <Placeholder
                        icon={<Icon56ServicesOutline />}
                        header="Ничего не найдено"
                    >
                        Поменяйте параметры поиска!
                    </Placeholder>
                );
            } else {
                items = (
                    <CardGrid>
                        {items}
                    </CardGrid>
                )
            }
        } else {
            items = (
                <Placeholder
                    icon={<Icon56InboxOutline />}
                    header="Пока ничего нет"
                >
                    В клубе еще нет книг
                </Placeholder>
            );
        }

		return (
			<Panel id={this.props.id}>
                <PanelHeader left={<PanelHeaderButton><Icon24BrowserBack onClick={() => {this.state.id ? this.props.functions.goToPage('clubs.view', {id: this.state.id}) : this.props.functions.goToPage('clubs')}}/></PanelHeaderButton>}>
                    {this.state.id ? "Редактировать клуб": "Добавить клуб"}
                </PanelHeader>
                <FormLayout id="club-form">
                    <FormLayoutGroup top={<Header mode="secondary">Фото для обложки</Header>}>
                        <SimpleCell disabled before={<img style={{ marginLeft: 12, maxWidth: imageWidth, maxHeight: imageWidth*1.1, borderRadius: borderRadius }} src={this.state.isLocalImage ? this.state.imageUrl : this.context.domain + "/files" + this.doResizeImageUrl(this.state.imageUrl, imageWidth, imageHeight)} alt="" />}>
                            <File id="club-image" before={<Icon24Camera />} size="l" mode="secondary" onChange={this.onImageChoose}>Выбрать из галереи</File>
                        </SimpleCell>
                    </FormLayoutGroup>
                    <Input type="text" name="name" defaultValue={this.state.name} placeholder="Наименование" top="Наименование" />
                    <Textarea top="Описание" name="description" placeholder="Здесь описание клуба..." style={{minHeight: "150px"}} value={this.state.description} onChange={this.descriptionChange}/>
                    {this.state.id > 0 &&
                    <Group header={<Header mode="secondary">Книги</Header>}>
                        {
                            booksCount > 0 &&
                            <Search value={this.state.search} onChange={this.onSearchChange} placeholder="Название, автор"/>
                        }
                        {items}
                        <Div style={{display: 'flex'}}>
                            <Button size="l" mode="secondary" stretched style={{marginRight: 8}} before={<Icon24Add/>}
                                    onClick={() => this.props.functions.goToPage('clubs.book_in_club', {
                                        club_id: this.state.id,
                                        book_in_club_id: 0
                                    })}>Добавить книгу</Button>
                        </Div>
                    </Group>
                    }
                    <FixedLayout vertical="bottom">
                        <Div style={{display: 'flex'}}>
                            <Button stretched onClick={()=>this.saveClub()}>Сохранить</Button>
                        </Div>
                    </FixedLayout>
                    <input type="hidden" name="id" value={this.state.id}/>
                    {this.state.snackbar}
                </FormLayout>
            </Panel>
		);
	}
}

export default ClubEdit;