import React from 'react';
import { platform, ModalPage, ModalPageHeader, Div, PanelHeaderButton } from '@vkontakte/vkui';
import AvatarEditor from 'react-avatar-editor'
import Icon24Cancel from '@vkontakte/icons/dist/24/cancel';
import Icon24Done from '@vkontakte/icons/dist/24/done';
import Slider from '@material-ui/core/Slider';

class PhotoEditor extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            scale: 1,
        }
        this.finishEdit = this.finishEdit.bind(this);
        this.cancelEdit = this.cancelEdit.bind(this);
        this.changeScale = this.changeScale.bind(this);
    }

    setEditorRef = (editor) => this.editor = editor

    finishEdit(){
        this.props.functions.closePhotoEdit(this.editor.getImage().toDataURL())

        /*const canvas = this.editor.getImage().toDataURL();
        fetch(canvas)
        .then(res => res.blob())
        .then(blob => {
            this.props.functions.closePhotoEdit(window.URL.createObjectURL(blob))
        });*/
    }

    cancelEdit(){
        this.props.functions.closePhotoEdit(null)
    }

    changeScale(value){
        this.setState({scale: 2})
    }

    render() {
        const osname = platform()
        const IS_PLATFORM_ANDROID = osname ==='android'
        const IS_PLATFORM_IOS = !IS_PLATFORM_ANDROID

        var imageWidth = this.props.imageWidth ? this.props.imageWidth : Math.round((document.documentElement.clientWidth || document.body.clientWidth || window.innerWidth)*0.72);
        var imageHeight = this.props.imageHeight ? this.props.imageHeight : Math.round(imageWidth / 2 * 3);
        var borderRadius = Math.round(imageWidth / 20);

        return (
            <ModalPage
                id={this.props.id}
                onClose={this.finishEdit}
                header={
                    <ModalPageHeader
                        left={IS_PLATFORM_ANDROID && <PanelHeaderButton onClick={this.cancelEdit}><Icon24Cancel /></PanelHeaderButton>}
                        right={<PanelHeaderButton onClick={this.finishEdit}>{IS_PLATFORM_IOS ? 'Готово' : <Icon24Done />}</PanelHeaderButton>}
                    >
                    Выбор фото
                    </ModalPageHeader>
                }
            >
                <Div style={{textAlign: 'center'}}>
                    <AvatarEditor
                        image={this.props.imageUrl}
                        ref={this.setEditorRef}
                        width={imageWidth}
                        height={imageHeight}
                        border={borderRadius*1.5}
                        borderRadius={borderRadius}
                        color={[255, 255, 255, 0.6]} // RGBA
                        scale={this.state.scale}
                        rotate={0}
                    />
                    <Slider
                        getAriaValueText={() => this.state.scale}
                        value={this.state.scale}
                        step={0.1}
                        marks={[{value: 1, label: 'x1'}, {value: 2, label: 'x2'}, {value: 5, label: 'x5'}]}
                        min={0.1}
                        max={5}
                        onChange={(event, newValue)=>this.setState({scale: newValue})}
                    />
                </Div>
            </ModalPage>
        );

    }
}

export default PhotoEditor;