import React from 'react';
import {
    Panel, PanelHeaderButton, PanelHeader, Div, Avatar, Title
    , Text, Snackbar, Button, PanelHeaderContent, Alert, ScreenSpinner, Card, CardGrid, Placeholder
} from '@vkontakte/vkui';
import Icon24BrowserBack from '@vkontakte/icons/dist/24/browser_back';
import {AppSettingsContext} from '../app-settings-context';
import Icon16Done from '@vkontakte/icons/dist/16/done';
import Icon16CancelCircleOutline from '@vkontakte/icons/dist/16/cancel_circle_outline';
import Icon24Favorite from '@vkontakte/icons/dist/24/favorite';
import bridge from "@vkontakte/vk-bridge";
import Icon56ServicesOutline from '@vkontakte/icons/dist/56/services_outline';

class Review extends React.Component {
    static contextType = AppSettingsContext;

	constructor(props) {
        super(props);

        this.state ={
            id: props.route.params.id,
            bookId: 0,
            imageUrl: "/book.png",
            authorsString: "",
            isLocalImage: false,
            name: 'Загружаем...',
            authorList: [{id: -new Date().getTime()}],
            addedDateTimeStr: '',
            readDateStr: null,
            rating: 4,
            comment: "",
            public: false,
            tagList: null,
            canEdit: false,
            authorFio: "",
            type: this.props.activeReviewType,
            snackbar: null,
        }

        this.getReview = this.getReview.bind(this);
        this.showSnackBar = this.showSnackBar.bind(this);
        this.deleteReview = this.deleteReview.bind(this);
        this.share = this.share.bind(this);
        this.publish = this.publish.bind(this);
	}

    componentDidMount() {
        this.getReview();
    }

    getReview() {
        if (this.state.id){
            fetch(this.context.domain + "/api/review?id=" + this.state.id + "&" + window.location.search.substr(1))
            .then((res) => res.json())
            .then(
                (result) => {
                    if (result.status === "SUCCESS"){
                        if (result.data){
                            this.setState({
                                id: result.data.id,
                                bookId: result.data.book.id,
                                name: result.data.book.name,
                                imageUrl: result.data.imageUrl,
                                authorsString: result.data.book.authorsString,
                                isLocalImage: false,
                                authorList: result.data.book.authorList,
                                addedDateTimeStr: result.data.addedDateTimeStr,
                                readDateStr: result.data.readDateStr ? result.data.readDateStr : 'неизвестно',
                                rating: result.data.rating,
                                comment: result.data.comment,
                                public: result.data.public,
                                tagList: result.data.tagList,
                                canEdit: result.data.canEdit,
                                authorFio: result.data.user.fio,
                                type: result.data.type,
                            });
                        } else {
                            this.setState({
                                id: 0,
                                name: '',
                            });
                        }
                    } else {
                        this.showSnackBar("Ошибка: " + result.message, true);
                    }
                    this.props.functions.changePopout(null);
                },
                (error) => {
                    /*TODO: Тут нужна обработка ошибки */
                    this.props.functions.changePopout(null);
                    this.showSnackBar("Ошибка сервера. Попробуйте перезапустить приложение", true);
                }
            ); 
        } else {
            this.props.functions.changePopout(null);
        }
    }

    showSnackBar (message, isError = false) {
        if (this.state.snackbar) return;
        this.setState({ snackbar:
          <Snackbar
            layout="vertical"
            onClose={() => this.setState({ snackbar: null })}
            before={isError ? <Avatar size={24} style={{backgroundColor: 'var(--dynamic_red)'}}><Icon16CancelCircleOutline fill="#fff" width={14} height={14} /></Avatar>
                        :<Avatar size={24} style={{backgroundColor: 'var(--accent)'}}><Icon16Done fill="#fff" width={14} height={14} /></Avatar>}
          >
            {message}
          </Snackbar>
        });
    }

    doResizeImageUrl(image, x, y){
        return image.substring(0, image.lastIndexOf('.')) + '-' + x + 'x' + y + image.substring(image.lastIndexOf('.'));
    }

    deleteReview(){
        this.props.functions.changePopout(<Alert
            actions={[{
              title: 'Отмена',
              autoclose: true,
              mode: 'cancel'
            }, {
              title: 'Удалить',
              autoclose: true,
              action: () => {
                this.props.functions.changePopout(<ScreenSpinner />);
                const formData = new FormData();
                var item = {id: this.state.id};
                formData.append('reviewJson', JSON.stringify(item));
                fetch(this.context.domain + "/api/review?delete&" + window.location.search.substr(1), {
                    method: 'POST',
                    body: formData
                }).then((res) => res.json())
                .then(
                    (result) => {
                        if (result.status === "SUCCESS"){
                            this.props.functions.showReviewList(this.state.type)
                        } else {
                            this.showSnackBar("Ошибка: " + result.message, true);
                        }
                        this.props.functions.changePopout(null);
                    },
                    (error) => {
                        /*TODO: Тут нужна обработка ошибки */
                        this.props.functions.changePopout(null);
                        this.showSnackBar("Ошибка сервера. Попробуйте перезапустить приложение", true);
                    }
                )
              },
            }]}
            onClose={() => this.props.functions.changePopout(null)}
          >
            <h2>Подтвердите действие</h2>
            <p>Удаленную книгу нельзя будет восстановить. Вы уверены, что хотите удалить?</p>
          </Alert>)
    }

    publish(){
        this.props.functions.changePopout(<Alert
            actions={[{
                title: 'Отмена',
                autoclose: true,
                mode: 'cancel'
            }, {
                title: 'Опубликовать',
                autoclose: true,
                action: () => {
                    this.props.functions.changePopout(<ScreenSpinner />);
                    const formData = new FormData();
                    var item = {id: this.state.id};
                    formData.append('reviewJson', JSON.stringify(item));
                    fetch(this.context.domain + "/api/review?publish&" + window.location.search.substr(1), {
                        method: 'POST',
                        body: formData
                    }).then((res) => res.json())
                        .then(
                            (result) => {
                                if (result.status === "SUCCESS"){
                                    this.setState({public: true}, () => this.showSnackBar("Книга успешно опубликована!"))

                                }
                                this.props.functions.changePopout(null);
                            },
                            (error) => {
                                /*TODO: Тут нужна обработка ошибки */
                                this.props.functions.changePopout(null);
                                this.showSnackBar("Ошибка сервера. Попробуйте перезапустить приложение", true);
                            }
                        )
                },
            }]}
            onClose={() =>this.props.functions.changePopout(null)}
        >
            <h2>Подтвердите действие</h2>
            <p>Вы действительно хотите опубликовать свой комментарий о книге?</p>
        </Alert>)
    }

    share(){
        var url = 'https://vk.com/app7445753_20049297#review_id=' + this.state.id
        if (navigator.share) {
            alert(2)
            navigator.share({
                title: 'Поделиться',
                text: 'Вы можете поделиться вашей книгой!',
                url: url
            }).then(() => {
                //console.log('Thanks for sharing!');
            })
                .catch(console.error);
        } else {
            bridge.send("VKWebAppCopyText", {text: url});

            if (navigator.clipboard){
                navigator.clipboard.writeText(url)
            }

            alert('Ссылка скопирована в буфер обмена')
        }
    }

	render() {
        var imageWidth = Math.round((document.documentElement.clientWidth || document.body.clientWidth || window.innerWidth)*0.82);
        var imageHeight = Math.round(imageWidth / 2 * 3);
        var margin = Math.round((document.documentElement.clientWidth || document.body.clientWidth || window.innerWidth)*0.05);
        var borderRadius = Math.round(imageWidth / 20);

		return (
			<Panel id={this.props.id}>
                <PanelHeader left={<PanelHeaderButton><Icon24BrowserBack onClick={() => this.props.functions.showReviewList(this.state.type)}/></PanelHeaderButton>}>
                    <PanelHeaderContent
                        status={this.state.id ?
                            this.state.type=='finished' && this.state.readDateStr ? "Прочитана: " + this.state.readDateStr : ' '
                            : 'Книга не найдена'}
                        //before={<img style={{ marginLeft: margin, maxWidth: "36px", maxHeight: "36px" }} src={this.state.isLocalImage ? this.state.imageUrl : this.context.domain + "/files" + this.doResizeImageUrl(this.state.imageUrl, 36, 36)} />}
                    >
                        {this.state.name}
                    </PanelHeaderContent>
                </PanelHeader>
                {this.state.id ?
                    <React.Fragment>
                        <Div style={{display: 'flex'}}>
                            <Text>Автор: <b>{this.state.authorFio}</b></Text>
                        </Div>
                        {!this.state.public &&
                            <Div style={{display: 'flex'}}>
                                <Button stretched onClick={this.publish} mode="commerce">Опубликовать</Button>
                            </Div>
                        }
                        {this.state.public &&
                            <Div style={{display: 'flex'}}>
                                <Button stretched onClick={this.share}>Поделиться</Button>
                            </Div>
                        }
                        <CardGrid>
                            <Card size="l" mode="shadow">
                                <img style={{ margin: margin, maxWidth: imageWidth, borderRadius: borderRadius }} src={this.state.isLocalImage ? this.state.imageUrl : this.context.domain + "/files" + this.doResizeImageUrl(this.state.imageUrl, imageWidth, imageHeight)} alt=""/>
                            </Card>
                        </CardGrid>
                        <Div>
                            <Title level="2" weight="heavy" caps style={{ marginBottom: 8 }}>{this.state.name}</Title>
                            <Text weight="medium" style={{ marginBottom: 16, color: "gray" }}>{this.state.authorsString}</Text>
                            {Array(this.state.rating).fill(<Icon24Favorite key={this.index} style={{float: "left"}} fill="rgb(255, 186, 0)"/>)}
                            <br/>
                        </Div>
                        {this.state.comment &&
                            <Div>
                                <Text weight="regular" style={{ marginBottom: 16, whiteSpace: "pre-wrap" }}>{this.state.comment}</Text>
                            </Div>
                        }
                        {this.state.tagList &&
                            <Div>
                                <Text weight="regular" style={{ marginBottom: 8, color: "#3f8ae0"}}>{this.state.tagList}</Text>
                            </Div>
                        }
                        {this.state.canEdit &&
                            <Div style={{display: 'flex'}}>
                                <Button stretched style={{"marginRight": 8}}
                                        onClick={() => this.props.functions.showReviewEdit(this.state.id, this.state.type)}>Редактировать</Button>
                                <Button mode="secondary" stretched onClick={this.deleteReview}>Удалить</Button>
                            </Div>
                        }
                    </React.Fragment>
                    :
                    <React.Fragment>
                        <Placeholder
                            icon={<Icon56ServicesOutline/>}
                            header="Книга не найдена"
                        >
                            Книги не существует или она еще не опубликована
                        </Placeholder>
                    </React.Fragment>
                }
                {this.state.snackbar}
            </Panel>
		);
	}
}

export default Review;