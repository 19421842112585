import React from 'react';
import {
    Alert,
    Avatar,
    Button,
    Div, Group,
    Panel,
    PanelHeader,
    PanelHeaderButton,
    PanelHeaderContent,
    ScreenSpinner,
    Snackbar,
    Text,
    Title,
    List,
    Cell,
    Link,
    UsersStack, Placeholder, CardGrid, Header, Search,
} from '@vkontakte/vkui';
import Icon24BrowserBack from '@vkontakte/icons/dist/24/browser_back';
import {AppSettingsContext} from '../app-settings-context';
import Icon16Done from '@vkontakte/icons/dist/16/done';
import Icon16CancelCircleOutline from '@vkontakte/icons/dist/16/cancel_circle_outline';
import Icon24Add from '@vkontakte/icons/dist/24/add';
import Icon28InfoOutline from '@vkontakte/icons/dist/28/info_outline';
import bridge from "@vkontakte/vk-bridge";
import BookInClubWithReviewsListItem from "../components/BookInClubWithReviewsListItem";
import Icon56InboxOutline from '@vkontakte/icons/dist/56/inbox_outline';
import Icon56ServicesOutline from '@vkontakte/icons/dist/56/services_outline';
import Grid from "@material-ui/core/Grid";

class Club extends React.Component {
    static contextType = AppSettingsContext;

	constructor(props) {
        super(props);

        this.state ={
            id: props.route.params.id,
            imageUrl: "/club.png",
            isLocalImage: false,
            name: null,
            description: null,
            addedDateTimeStr: null,
            canEdit: false,
            clubStatus: "NOT_MEMBER",
            creator: {},
            memberList: [],
            approveList: [],
            snackbar: null,
            bookList: null,
            search: "",
        }

        this.getClub = this.getClub.bind(this);
        this.showSnackBar = this.showSnackBar.bind(this);
        this.deleteClub = this.deleteClub.bind(this);
        this.joinClub = this.joinClub.bind(this);
        this.leaveClub = this.leaveClub.bind(this);
        this.share = this.share.bind(this);
        this.onSearchChange = this.onSearchChange.bind(this);
	}

    componentDidMount() {
        this.getClub();
    }

    getClub() {
        if (this.state.id){
            fetch(this.context.domain + "/api/club?id=" + this.state.id + "&" + window.location.search.substr(1))
            .then((res) => res.json())
            .then(
                (result) => {
                    if (result.status === "SUCCESS"){
                        if (result.data){
                            this.setState({
                                id: result.data.id,
                                name: result.data.name,
                                description: result.data.description,
                                imageUrl: result.data.imageUrl,
                                isLocalImage: false,
                                addedDateTimeStr: result.data.addedDateTimeStr,
                                canEdit: result.data.canEdit,
                                clubStatus: result.data.clubStatus,
                                creator: result.data.creator,
                                memberList: result.data.memberList,
                                approveList: result.data.approveList,
                                bookList: result.data.bookList,
                            });
                        } else {
                            this.setState({
                                id: 0,
                            });
                        }
                    } else {
                        this.showSnackBar("Ошибка: " + result.message, true);
                    }
                    this.props.functions.changePopout(null);
                },
                (error) => {
                    /*TODO: Тут нужна обработка ошибки */
                    this.props.functions.changePopout(null);
                    this.showSnackBar("Ошибка сервера. Попробуйте перезапустить приложение", true);
                }
            ); 
        } else {
            this.props.functions.changePopout(null);
        }
    }

    showSnackBar (message, isError = false) {
        if (this.state.snackbar) return;
        this.setState({ snackbar:
          <Snackbar
            layout="vertical"
            onClose={() => this.setState({ snackbar: null })}
            before={isError ? <Avatar size={24} style={{backgroundColor: 'var(--dynamic_red)'}}><Icon16CancelCircleOutline fill="#fff" width={14} height={14} /></Avatar>
                        :<Avatar size={24} style={{backgroundColor: 'var(--accent)'}}><Icon16Done fill="#fff" width={14} height={14} /></Avatar>}
          >
            {message}
          </Snackbar>
        });
    }

    doResizeImageUrl(image, x, y){
        return image.substring(0, image.lastIndexOf('.')) + '-' + x + 'x' + y + image.substring(image.lastIndexOf('.'));
    }

    deleteClub(){
        this.props.functions.changePopout(<Alert
            actions={[{
              title: 'Отмена',
              autoclose: true,
              mode: 'cancel'
            }, {
              title: 'Удалить',
              autoclose: true,
              action: () => {
                this.props.functions.changePopout(<ScreenSpinner />);
                const formData = new FormData();
                var item = {id: this.state.id};
                formData.append('clubJson', JSON.stringify(item));
                fetch(this.context.domain + "/api/club?delete&" + window.location.search.substr(1), {
                    method: 'POST',
                    body: formData
                }).then((res) => res.json())
                .then(
                    (result) => {
                        if (result.status === "SUCCESS"){
                            this.props.functions.goToPage('clubs')
                        } else {
                            this.showSnackBar("Ошибка: " + result.message, true);
                        }
                        this.props.functions.changePopout(null);
                    },
                    (error) => {
                        /*TODO: Тут нужна обработка ошибки */
                        this.props.functions.changePopout(null);
                        this.showSnackBar("Ошибка сервера. Попробуйте перезапустить приложение", true);
                    }
                )
              },
            }]}
            onClose={() => this.props.functions.changePopout(null)}
          >
            <h2>Подтвердите действие</h2>
            <p>Удаленный клуб нельзя будет восстановить. Вы уверены, что хотите удалить?</p>
          </Alert>)
    }

    joinClub(){
        this.props.functions.changePopout(<Alert
            actions={[{
                title: 'Отмена',
                autoclose: true,
                mode: 'cancel'
            }, {
                title: 'Вступить',
                autoclose: true,
                action: () => {
                    this.props.functions.changePopout(<ScreenSpinner />);
                    const formData = new FormData();
                    var item = {id: this.state.id};
                    formData.append('clubJson', JSON.stringify(item));
                    fetch(this.context.domain + "/api/club?join&" + window.location.search.substr(1), {
                        method: 'POST',
                        body: formData
                    }).then((res) => res.json())
                        .then(
                            (result) => {
                                if (result.status === "SUCCESS"){
                                    this.setState({
                                        clubStatus: 'WAIT_TO_APPROVE',
                                    }, () => this.showSnackBar("Заявка успешно подана, ожидайте подтверждения!", false));
                                } else {
                                    this.showSnackBar("Ошибка: " + result.message, true);
                                }
                                this.props.functions.changePopout(null);
                            },
                            (error) => {
                                /*TODO: Тут нужна обработка ошибки */
                                this.props.functions.changePopout(null);
                                this.showSnackBar("Ошибка сервера. Попробуйте перезапустить приложение", true);
                            }
                        )
                },
            }]}
            onClose={() => this.props.functions.changePopout(null)}
        >
            <h2>Подтвердите действие</h2>
            <p>Вы уверены, что хотите вступить в клуб {this.state.name}?</p>
        </Alert>)
    }

    leaveClub(){
        this.props.functions.changePopout(<Alert
            actions={[{
                title: 'Отмена',
                autoclose: true,
                mode: 'cancel'
            }, {
                title: 'Покинуть',
                autoclose: true,
                action: () => {
                    this.props.functions.changePopout(<ScreenSpinner />);
                    const formData = new FormData();
                    var item = {id: this.state.id};
                    formData.append('clubJson', JSON.stringify(item));
                    fetch(this.context.domain + "/api/club?leave&" + window.location.search.substr(1), {
                        method: 'POST',
                        body: formData
                    }).then((res) => res.json())
                        .then(
                            (result) => {
                                if (result.status === "SUCCESS"){
                                    this.setState({
                                        clubStatus: 'NOT_MEMBER',
                                    }, () => this.showSnackBar("Вы покинули клуб!", false));
                                } else {
                                    this.showSnackBar("Ошибка: " + result.message, true);
                                }
                                this.props.functions.changePopout(null);
                            },
                            (error) => {
                                /*TODO: Тут нужна обработка ошибки */
                                this.props.functions.changePopout(null);
                                this.showSnackBar("Ошибка сервера. Попробуйте перезапустить приложение", true);
                            }
                        )
                },
            }]}
            onClose={() => this.props.functions.changePopout(null)}
        >
            <h2>Подтвердите действие</h2>
            <p>Вы уверены, что хотите покинуть клуб {this.state.name}?</p>
        </Alert>)
    }

    declOfNum(number, titles) {
        var cases = [2, 0, 1, 1, 1, 2];
        return titles[ (number%100>4 && number%100<20)? 2 : cases[(number%10<5)?number%10:5] ];
    }

    doResizeImageUrl(image, x, y){
        return image.substring(0, image.lastIndexOf('.')) + '-' + x + 'x' + y + image.substring(image.lastIndexOf('.'));
    }

    share(){
        var url = 'https://vk.com/app7445753_20049297#club_id=' + this.state.id
        if (navigator.share) {
            alert(2)
            navigator.share({
                title: 'Поделиться',
                text: 'Вы можете поделиться ссылкой на этот клуб!',
                url: url
            }).then(() => {
                //console.log('Thanks for sharing!');
            })
                .catch(console.error);
        } else {
            bridge.send("VKWebAppCopyText", {text: url});

            if (navigator.clipboard){
                navigator.clipboard.writeText(url)
            }

            alert('Ссылка скопирована в буфер обмена')
        }
    }

    onSearchChange(e){
        this.setState({ search: e.target.value });
    }

	render() {
        var imageWidth = Math.round((document.documentElement.clientWidth || document.body.clientWidth || window.innerWidth)*0.92);
        var imageHeight = imageWidth;
        var margin = Math.round((document.documentElement.clientWidth || document.body.clientWidth || window.innerWidth)*0.05);
        var borderRadius = Math.round(imageWidth / 20);

        var memberImageWidth = Math.round((document.documentElement.clientWidth || document.body.clientWidth || window.innerWidth) / 10);
        var memberImageHeight = memberImageWidth;

        var membersTitle = this.state.memberList.slice(0, 5).map((item) => {return item.name}).join(", ");
        if (this.state.memberList.length > 5){
            membersTitle += ' и ещё ' + (this.state.memberList.length - 5) + ' ' + this.declOfNum((this.state.memberList.length - 5), ['человек', 'человека', 'человек']);
        }

        var approveTitle = this.state.approveList.slice(0, 5).map((item) => {return item.name}).join(", ");
        if (this.state.approveList.length > 5){
            approveTitle += ' и ещё ' + (this.state.approveList.length - 5) + ' ' + this.declOfNum((this.state.approveList.length - 5), ['человек', 'человека', 'человек']);
        }

        let bookList = null;
        var booksCount = this.state.bookList ? this.state.bookList.length : 0
        if (booksCount){
            const search = this.state.search.toLowerCase();
            bookList = this.state.bookList
                .filter((item) =>
                    (
                        (item.book.name && item.book.name.toLowerCase().indexOf(search) > -1)
                        || (item.book.authorsString && item.book.authorsString.toLowerCase().indexOf(search) > -1)
                    )
                )
                .map((item) => {
                    return (
                        <BookInClubWithReviewsListItem key={item.id} data={item}/>
                    );
                });

            if (!bookList || !bookList.length){
                bookList = (
                    <Placeholder
                        icon={<Icon56ServicesOutline />}
                        header="Ничего не найдено"
                    >
                        Поменяйте параметры поиска!
                    </Placeholder>
                );
            }
        } else {
            bookList = (
                <Placeholder
                    icon={<Icon56InboxOutline />}
                    header="Пока ничего нет"
                >
                    В клубе еще нет книг
                </Placeholder>
            );
        }

		return (
			<Panel id={this.props.id}>
                <PanelHeader left={<PanelHeaderButton><Icon24BrowserBack onClick={()=>this.props.functions.goToPage('clubs')}/></PanelHeaderButton>}>
                <PanelHeaderContent
                    status={"Создан: " + this.state.addedDateTimeStr}
                >
                    {this.state.name}
                </PanelHeaderContent>
                </PanelHeader>
                    <Group>
                        <Div style={{display: 'flex'}}>
                            <Text>Создатель: <Link href={"https://vk.com/id" + this.state.creator.vkUserId} target="_blank">{this.state.creator.fio}</Link></Text>
                        </Div>
                            {this.state.clubStatus == 'NOT_MEMBER' ?
                                <Div style={{display: 'flex'}}>
                                    <Button size="l" stretched style={{marginRight: 8}} before={<Icon24Add/>}
                                            onClick={() => this.joinClub()}>Вступить в
                                        клуб</Button>
                                </Div>
                                :
                                this.state.clubStatus == 'MEMBER' ?
                                <Div style={{display: 'flex'}}>
                                    <Button size="l" stretched style={{marginRight: 8}}
                                            onClick={() => this.share()}>Поделиться</Button>
                                </Div>
                                :
                                <List>
                                    <Cell multiline before={<Icon28InfoOutline />}>Ожидаем подтверждения админа клуба</Cell>
                                </List>
                            }
                    </Group>
                    <img style={{ marginLeft: margin, maxWidth: imageWidth, borderRadius: borderRadius }} src={this.state.isLocalImage ? this.state.imageUrl : this.context.domain + "/files" + this.doResizeImageUrl(this.state.imageUrl, imageWidth, imageHeight)} alt="" />
                    <Div>
                        <Title level="2" weight="heavy" caps style={{ marginBottom: 8 }}>{this.state.name}</Title>
                        {this.state.description &&
                        <Div>
                            <Text weight="regular" style={{ marginBottom: 16, whiteSpace: "pre-wrap" }}>{this.state.description}</Text>
                        </Div>
                        }
                        {this.state.approveList.length > 0 &&
                        <React.Fragment>
                            <Text weight="medium" style={{ marginBottom: 16, color: "#e64646" }}>Заявки на вступление</Text>
                            <UsersStack
                                photos={this.state.approveList.map((item) => {return this.context.domain + "/files" + this.doResizeImageUrl(item.imageUrl, memberImageWidth, memberImageHeight)})}
                                size="m"
                                visibleCount={5}
                                layout="vertical"
                                onClick={()=>this.props.functions.goToPage('clubs.members', {id: this.state.id})}
                            >{approveTitle}
                            </UsersStack>
                        </React.Fragment>
                        }
                        <Text weight="medium" style={{ marginBottom: 16, color: "gray" }}>{this.state.memberList.length + ' ' + this.declOfNum(this.state.memberList.length, ['участник', 'участника', 'участников'])}</Text>
                        <UsersStack
                            photos={this.state.memberList.map((item) => {return this.context.domain + "/files" + this.doResizeImageUrl(item.imageUrl, memberImageWidth, memberImageHeight)})}
                            size="m"
                            visibleCount={5}
                            layout="vertical"
                            onClick={()=>this.props.functions.goToPage('clubs.members', {id: this.state.id})}
                        >{membersTitle}
                        </UsersStack>
                    </Div>
                    <Group header={<Header mode="secondary">Книги</Header>}>
                        {
                            booksCount > 0 &&
                            <Search value={this.state.search} onChange={this.onSearchChange} placeholder="Название, автор"/>
                        }
                        {bookList}
                    </Group>
                    {this.state.clubStatus == 'MEMBER' &&
                        <Div style={{display: 'flex'}}>
                            <Button size="l" mode="secondary" stretched style={{marginRight: 8}}
                                    before={<Icon16CancelCircleOutline/>}
                                    onClick={() => this.leaveClub()}>Покинуть
                                клуб</Button>
                        </Div>
                    }
                    {this.state.canEdit &&
                        <Div style={{display: 'flex'}}>
                            <Button stretched style={{"marginRight": 8}}
                                    onClick={()=>this.props.functions.goToPage('clubs.edit', {id: this.state.id})}>Редактировать</Button>
                            <Button mode="secondary" stretched onClick={this.deleteClub}>Удалить</Button>
                        </Div>
                    }
                    {this.state.snackbar}
            </Panel>
		);
	}
}

export default Club;