import React from 'react';
import {Epic, ModalRoot, Tabbar, TabbarItem, View} from '@vkontakte/vkui';
import '@vkontakte/vkui/dist/vkui.css';
import ReviewList from './panels/ReviewList';
import Review from './panels/Review';
import ReviewEdit from './panels/ReviewEdit';
import CollectionList from './panels/CollectionList';
import Collection from './panels/Collection';
import CollectionEdit from './panels/CollectionEdit';
import Message from './panels/Message';
import FeedList from './panels/FeedList';
import PhotoEditor from './components/PhotoEditor';
import AddReviewToCollection from './components/AddReviewToCollection';
import {AppSettingsContext} from './app-settings-context';
import {RouteNode} from 'react-router5'
import Icon28NewsfeedOutline from '@vkontakte/icons/dist/28/newsfeed_outline';
import Icon28CopyOutline from '@vkontakte/icons/dist/28/copy_outline';
import Icon28ListCheckOutline from '@vkontakte/icons/dist/28/list_check_outline';
import Icon28UserCircleOutline from '@vkontakte/icons/dist/28/user_circle_outline';
import Icon28Users3Outline from '@vkontakte/icons/dist/28/users_3_outline';
import Account from "./panels/Account";
import ClubList from "./panels/ClubList";
import Club from "./panels/Club";
import ClubEdit from "./panels/ClubEdit";
import ClubMembers from "./panels/ClubMembers";
import BookInClubEdit from "./panels/BookInClubEdit";

const curSettings = {domain: "https://iread.back.itbrick.ru"};
//const curSettings = {domain: "http://localhost:8080/iRead"};

class App extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            message: null,
            popout: null,
            activeModal: null,
            imageUrl: null,
            imageChooseCallback: null,
            reviewChooseCallback: null,
            currentListForReviewChoose: null,
            activeReviewType: 'finished',
            newFeedItemsCount: 0,
        }


        this.showReviewList = this.showReviewList.bind(this);
        this.showReview = this.showReview.bind(this);
        this.showReviewEdit = this.showReviewEdit.bind(this);

        this.showCollectionList = this.showCollectionList.bind(this);
        this.showCollection = this.showCollection.bind(this);
        this.showCollectionEdit = this.showCollectionEdit.bind(this);

        this.showAccount = this.showAccount.bind(this);
        this.showAccountSettings = this.showAccountSettings.bind(this);
        this.showAccountAbout = this.showAccountAbout.bind(this);

        this.changePopout = this.changePopout.bind(this);
        this.showMessage = this.showMessage.bind(this);
        this.onStoryChange = this.onStoryChange.bind(this);
        this.showPhotoEdit = this.showPhotoEdit.bind(this);
        this.closePhotoEdit = this.closePhotoEdit.bind(this);
        this.showChooseReview = this.showChooseReview.bind(this);
        this.closeChooseReview = this.closeChooseReview.bind(this);
        this.goToPage = this.goToPage.bind(this);
        this.newFeedItemsCountChange = this.newFeedItemsCountChange.bind(this);
    }

    componentDidMount() {
        var that = this
        if (window.location.hash){
            setTimeout(function() {
                var hash = window.location.hash.substring(1)
                var params = JSON.parse('{"' + decodeURI(hash).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"').replace(/%2C/g, ',') + '"}')
                if (params.collection_id) {
                    that.showCollection(params.collection_id)
                } else if (params.review_id) {
                    that.showReview(params.review_id, that.state.activeReviewType)
                } else if (params.club_id) {
                    this.goToPage('clubs.view', {id: params.club_id})
                }
            }, 100)
        }
    }

    onStoryChange (e) {
        var search = window.location.search.substring(1)
        var params = JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"').replace(/%2C/g,',') + '"}')
        this.props.router.navigate(e.currentTarget.dataset.story, params, {reload: true})
    }

    changePopout(popout){
        this.setState({
            popout: popout,
        });
    }

    showMessage(message){
        var search = window.location.search.substring(1)
        var params = JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"').replace(/%2C/g,',') + '"}')
        var router = this.props.router;
        this.setState({
            message: message,
        }, ()=>router.navigate('message', params, {reload: true}));
    }

    showPhotoEdit(imageUrl, callback){
        this.setState({
            imageUrl: imageUrl,
            activeModal: "photo-edit",
            imageChooseCallback: callback
        });
    }

    closePhotoEdit(imageUrl){
        this.setState({
            activeModal: null,
        }, () => this.state.imageChooseCallback(imageUrl));
    }

    showChooseReview(currentList, callback){
        this.setState({
            activeModal: "add-review-to-collection",
            reviewChooseCallback: callback,
            currentListForReviewChoose: currentList,
        });
    }

    closeChooseReview(review){
        this.setState({
            activeModal: null,
        }, () => this.state.reviewChooseCallback(review));
    }

    goToPage(page, pageParams){
        var search = window.location.search.substring(1)
        var params = JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"').replace(/%2C/g,',') + '"}')
        if (pageParams){
            for (var prop in pageParams) {
                params[prop] = pageParams[prop]
            }
        }
        var router = this.props.router;
        router.navigate(page, params, {reload: true})
    }

    newFeedItemsCountChange(value){
        this.setState({
            newFeedItemsCount: value,
        });
    }

  /* reviews */
  showReviewList(activeReviewType){
      if (!activeReviewType){
          activeReviewType = 'finished'
      }
      this.setState({
          activeReviewType: activeReviewType,
      }, () => {
          var search = window.location.search.substring(1)
          var params = JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"').replace(/%2C/g, ',') + '"}')
          var router = this.props.router;
          router.navigate('reviews', params, {reload: true})
      });
  }

  showReview(id, activeReviewType){
      if (!activeReviewType){
          activeReviewType = 'finished'
      }
      this.setState({
          activeReviewType: activeReviewType,
      }, () => {
            var search = window.location.search.substring(1)
            var params = JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"').replace(/%2C/g,',') + '"}')
            params.id = id;
            var router = this.props.router;
            router.navigate('reviews.view', params, {reload: true})
      });
  }

  showReviewEdit(id, activeReviewType){
      if (!activeReviewType){
          activeReviewType = 'finished'
      }
      this.setState({
          activeReviewType: activeReviewType,
      }, () => {
            var search = window.location.search.substring(1)
            var params = JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"').replace(/%2C/g,',') + '"}')
            params.id = id;
            var router = this.props.router;
            router.navigate('reviews.edit', params, {reload: true})
      });
  }
  /* end of review */

  /* collections */
  showCollectionList(){
    var search = window.location.search.substring(1)
    var params = JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"').replace(/%2C/g,',') + '"}')
    var router = this.props.router;
    router.navigate('collections', params, {reload: true})
  }

  showCollection(id){
    var search = window.location.search.substring(1)
    var params = JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"').replace(/%2C/g,',') + '"}')
    params.id = id;
    var router = this.props.router;
    router.navigate('collections.view', params, {reload: true})
  }

  showCollectionEdit(id){
    var search = window.location.search.substring(1)
    var params = JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"').replace(/%2C/g,',') + '"}')
    params.id = id;
    var router = this.props.router;
    router.navigate('collections.edit', params, {reload: true})
  }
  /* end of collections */

  /* account */
  showAccount(){
    var search = window.location.search.substring(1)
    var params = JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"').replace(/%2C/g,',') + '"}')
    var router = this.props.router;
    router.navigate('account', params, {reload: true})
  }

  showAccountSettings(){
    var search = window.location.search.substring(1)
    var params = JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"').replace(/%2C/g,',') + '"}')
    var router = this.props.router;
    router.navigate('account.settings', params, {reload: true})
  }

  showAccountAbout(){
    var search = window.location.search.substring(1)
    var params = JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"').replace(/%2C/g,',') + '"}')
    var router = this.props.router;
    router.navigate('account.about', params, {reload: true})
  }

  /* end of account */

  render(){
    let {
        route
    } = this.props

    //const activeView = (route.name === 'add') ? 'addView' : 'tasksView'
    //const activeView = this.state.activeStory
    var activePath = (route ? route.name : "feed").split(".")
    const activeStory = activePath[0]
    const activePanel = activePath.length>1 ? activePath[1] : activeStory

    var functions = {
        changePopout: this.changePopout,
        showMessage: this.showMessage,
        showPhotoEdit: this.showPhotoEdit,
        closePhotoEdit: this.closePhotoEdit,
        showChooseReview: this.showChooseReview,
        closeChooseReview: this.closeChooseReview,
        showReview: this.showReview,
        showReviewEdit: this.showReviewEdit,
        showReviewList: this.showReviewList,
        showCollection: this.showCollection,
        showCollectionEdit: this.showCollectionEdit,
        showCollectionList: this.showCollectionList,
        showAccount: this.showAccount,
        showAccountSettings: this.showAccountSettings,
        showAccountAbout: this.showAccountAbout,
        goToPage: this.goToPage,
        newFeedItemsCountChange: this.newFeedItemsCountChange,
    }

    var rectangleScreenWidth = this.props.imageWidth ? this.props.imageWidth : Math.round((document.documentElement.clientWidth || document.body.clientWidth || window.innerWidth)*0.72);

    return (
        <AppSettingsContext.Provider value={curSettings}>
            <Epic activeStory={activeStory} tabbar={
                <Tabbar>
                    <TabbarItem
                        onClick={this.onStoryChange}
                        selected={activeStory === 'collections'}
                        data-story="collections"
                        text="Подборки"
                    ><Icon28CopyOutline /></TabbarItem>
                    <TabbarItem
                        onClick={this.onStoryChange}
                        selected={activeStory === 'reviews'}
                        data-story="reviews"
                        text="Прочитано"
                    ><Icon28ListCheckOutline /></TabbarItem>
                    <TabbarItem
                        onClick={this.onStoryChange}
                        selected={activeStory === 'feed'}
                        data-story="feed"
                        label={this.state.newFeedItemsCount ? this.state.newFeedItemsCount : ''}
                        text="Лента"
                    ><Icon28NewsfeedOutline /></TabbarItem>
                    <TabbarItem
                        onClick={this.onStoryChange}
                        selected={activeStory === 'clubs'}
                        data-story="clubs"
                        text="Клубы"
                    ><Icon28Users3Outline /></TabbarItem>
                    <TabbarItem
                        onClick={this.onStoryChange}
                        selected={activeStory === 'account'}
                        data-story="account"
                        text="Аккаунт"
                    ><Icon28UserCircleOutline /></TabbarItem>
                </Tabbar>
            }>
                <View id='collections' popout={this.state.popout} activePanel={activePanel} modal={
                        <ModalRoot activeModal={this.state.activeModal}>
                            <PhotoEditor id="photo-edit" functions={functions} activeModal={this.state.activeModal} imageUrl={this.state.imageUrl}/>
                            <AddReviewToCollection id="add-review-to-collection" functions={functions} activeModal={this.state.activeModal} currentListForReviewChoose={this.state.currentListForReviewChoose}/>
                        </ModalRoot>
                        }>
                    <CollectionList id="collections" functions={functions}></CollectionList>
                    <Collection id="view" functions={functions} route={route}></Collection>
                    <CollectionEdit id="edit" functions={functions} route={route}></CollectionEdit>
                </View>
                <View id='reviews' popout={this.state.popout} activePanel={activePanel} modal={
                        <ModalRoot activeModal={this.state.activeModal}>
                            <PhotoEditor id="photo-edit" functions={functions} imageUrl={this.state.imageUrl}/>
                        </ModalRoot>
                        }>
                    <ReviewList id="reviews" functions={functions} activeReviewType={this.state.activeReviewType}></ReviewList>
                    <Review id="view" functions={functions} route={route} activeReviewType={this.state.activeReviewType}></Review>
                    <ReviewEdit id="edit" functions={functions} route={route} activeReviewType={this.state.activeReviewType}></ReviewEdit>
                </View>
                <View id='feed' popout={this.state.popout} activePanel={activePanel}>
                    <FeedList id="feed" functions={functions}></FeedList>
                </View>
                <View id='clubs' popout={this.state.popout} activePanel={activePanel} modal={
                    <ModalRoot activeModal={this.state.activeModal}>
                        <PhotoEditor id="photo-edit" functions={functions} activeModal={this.state.activeModal} imageUrl={this.state.imageUrl} imageWidth={rectangleScreenWidth} imageHeight={rectangleScreenWidth}/>
                    </ModalRoot>
                }>
                    <ClubList id="clubs" functions={functions}></ClubList>
                    <Club id="view" functions={functions} route={route}></Club>
                    <ClubEdit id="edit" functions={functions} route={route}></ClubEdit>
                    <ClubMembers id="members" functions={functions} route={route}></ClubMembers>
                    <BookInClubEdit id="book_in_club" functions={functions} route={route}></BookInClubEdit>
                </View>
                <View id='account' popout={this.state.popout} activePanel={activePanel} modal={
                    <ModalRoot activeModal={this.state.activeModal}>
                        <PhotoEditor id="photo-edit" functions={functions} activeModal={this.state.activeModal} imageUrl={this.state.imageUrl} imageWidth={rectangleScreenWidth} imageHeight={rectangleScreenWidth}/>
                    </ModalRoot>
                }>
                    <Account id="account" functions={functions} route={route}/>
                </View>
                <View id='message' popout={this.state.popout} activePanel={activePanel}>
                    <Message id="message" functions={functions} message={this.state.message} activeReviewType={this.state.activeReviewType}></Message>
                </View>
            </Epic>
        </AppSettingsContext.Provider>
    );
  }
}
 
export default (props) => (
    <RouteNode nodeName="">
        {({ route }) => <App route={route} {...props}/>}
    </RouteNode>
)

/*

bridge.send("VKWebAppGetUserInfo", {})
            .then(data => {// Запрос
                this.setState({
                    user: data,
                });
            }, error => {
                this.showMessage("Произошла ошибка при получении данных о пользователе. Попробуйте перезапустить приложение")
            });

  getAccess(){
    this.setState({ 
        activePanel: 'message',
        message: <Div>Загружаем информацию...</Div>,
        popout: <ScreenSpinner />,
    });

    bridge.send("VKWebAppGetAuthToken", {"app_id": 7445753, "scope": ""})
        .then((data) => {
            if (data.access_token){
                this.setState({ 
                    accessToken : data.access_token,
                    activePanel: 'book-list',
                    message: null,
                    popout: null,
                });
            } else {
                this.setState({
                    activePanel: "error",
                    popout: null,
                    message: <Div>Приложению необходимы данные о пользователе. Разрешите доступ
                               <br/>
                               <Button size="l" stretched style={{ marginRight: 8 }} before={<Icon24UserOutgoing/>} onClick={this.getAccess}>Запросить заново</Button>
                             </Div>,
                });
            }
        });
  }
*/