import React from 'react';
import {Button, Div, RichCell, Text, Card} from '@vkontakte/vkui';

import {AppSettingsContext} from '../app-settings-context';

class BookInClubListItem extends React.Component {
    static contextType = AppSettingsContext;

    doResizeImageUrl(image, x, y){
        return image.substring(0, image.lastIndexOf('.')) + '-' + x + 'x' + y + image.substring(image.lastIndexOf('.'));
    }

    render() {
        var imageWidth = Math.round((document.documentElement.clientWidth || document.body.clientWidth || window.innerWidth) / 5);
        var imageHeight = Math.round(imageWidth / 2 * 3);
        var borderRadius = Math.round(imageWidth / 20);

        return (
            <Card size="l" mode="shadow">
                <RichCell
                    multiline
                    before={<Div><img style={{ maxWidth: imageWidth, maxHeight: imageWidth*2, borderRadius: borderRadius }} src={this.context.domain + "/files" + this.doResizeImageUrl(this.props.data.book.imageUrl, imageWidth, imageHeight)} alt="" /></Div>}
                    text={<Text weight="regular" style={{ color: "gray"}}>{this.props.data.book.authorsString}</Text>}
                    caption={<Text weight="regular" style={{ color: "var(--text_secondary)", fontSize: 13, marginTop: 5}}>Ведущий: {this.props.data.master.fio}</Text>}
                    onClick={this.props.onClick}
                    style={{padding: 0}}
                >
                    <b>{this.props.data.book.name}</b>
                </RichCell>
            </Card>
        );
    }
}

export default BookInClubListItem;