import React from 'react';
import {
    Button,
    Div,
    List,
    ModalPage,
    ModalPageHeader,
    PanelHeaderButton,
    Placeholder,
    platform,
    Search,
    Cell,
    CardGrid
} from '@vkontakte/vkui';
import ReviewListItem from './ReviewListItem';
import Icon24Cancel from '@vkontakte/icons/dist/24/cancel';
import Icon24Add from '@vkontakte/icons/dist/24/add';
import Icon56LikeOutline from '@vkontakte/icons/dist/56/like_outline';
import Icon56ServicesOutline from '@vkontakte/icons/dist/56/services_outline';
import {AppSettingsContext} from '../app-settings-context';

class AddReviewToCollection extends React.Component {
    static contextType = AppSettingsContext;

    constructor(props) {
        super(props);

        this.state = {
            items: null,
            search: "",
        }

        this.getItems = this.getItems.bind(this);
        this.onSearchChange = this.onSearchChange.bind(this);
        this.chooseReview = this.chooseReview.bind(this);
        this.cancel = this.cancel.bind(this);
    }

    componentDidMount() {
        this.getItems(); 
    }

    chooseReview(id){
        this.props.functions.closeChooseReview(this.state.items
            .find((item) => {return item.id === id}))
    }

    cancel(){
        this.props.functions.closeChooseReview(null)
    }

    getItems() {
        fetch(this.context.domain + "/api/review?list&public&" + window.location.search.substr(1))
        .then((res) => res.json())
        .then(
            (result) => {
                if (result.status === "SUCCESS"){
                    this.setState({
                        items: result.data.filter(item => {
                            result = true
                            if (this.props.currentListForReviewChoose) {
                                this.props.currentListForReviewChoose.forEach(itemInCurrentList => {
                                    if (itemInCurrentList.id === item.id) {
                                        result = false
                                    }
                                })
                            }
                            return result
                        }),
                    });
                } else {
                    this.props.functions.showMessage(<Div>Некорректный пользователь. Перезапустите приложение.</Div>);
                }
                this.props.functions.changePopout(null);
            },
            (error) => {
                /*TODO: Тут нужна обработка ошибки */
            }
        ); 
    }

    onSearchChange(e){
        this.setState({ search: e.target.value });
    }

    render() {
        const osname = platform()
        const IS_PLATFORM_ANDROID = osname ==='android'
        // const IS_PLATFORM_IOS = !IS_PLATFORM_ANDROID

        let items = null;
        let itemsCount = this.state.items ? this.state.items.length : 0

        if (itemsCount){
            const search = this.state.search.toLowerCase();
            items = this.state.items
                .filter((item) => 
                        (
                            (item.book.name && item.book.name.toLowerCase().indexOf(search) > -1) 
                                || (item.book.authorsString && item.book.authorsString.toLowerCase().indexOf(search) > -1) 
                                || (item.tagList && item.tagList.toLowerCase().indexOf(search) > -1) 
                            )
                    )
                .map((item) => {
                    return (
                        <ReviewListItem key={item.id} data={item} onClick={() => this.chooseReview(item.id)}/>
                    );
                });

                if (!items || !items.length){
                    items = (
                        <Placeholder
                            icon={<Icon56ServicesOutline />}
                            header="Ничего не найдено"
                            action={<Button size="l" mode="secondary" stretched style={{ marginRight: 8 }} before={<Icon24Add/>} onClick={() => this.props.functions.showReviewEdit(0, 'finished')}>Добавить книгу</Button>}
                        >
                            Поменяйте параметры поиска!
                        </Placeholder>
                    );
                } else {
                    items = (
                        <CardGrid>
                            {items}
                        </CardGrid>
                    )
                }
        } else {
            items = (
                <Placeholder
                    icon={<Icon56LikeOutline />}
                    header="Пока ничего нет"
                    action={<Button size="l" mode="secondary" stretched style={{ marginRight: 8 }} before={<Icon24Add/>} onClick={() => this.props.functions.showReviewEdit(0, 'finished')}>Добавить книгу</Button>}
                >
                    Прочитайте книгу и добавьте ее сюда!
                </Placeholder>
            );
        }

        return (
            <ModalPage
                id={this.props.id}
                onClose={this.finishEdit}
                header={
                    <ModalPageHeader
                        left={IS_PLATFORM_ANDROID && <PanelHeaderButton onClick={this.cancel}><Icon24Cancel /></PanelHeaderButton>}
                    >
                    Выбор фото
                    </ModalPageHeader>
                }
            >

                    <Search value={this.state.search} onChange={this.onSearchChange} placeholder="Название, автор, тег"/>
                    {items}
                <List><Cell></Cell><Cell></Cell><Cell></Cell><Cell></Cell><Cell></Cell></List>
                <List><Cell></Cell><Cell></Cell><Cell></Cell><Cell></Cell><Cell></Cell></List>
            </ModalPage>
        );

    }
}

export default AddReviewToCollection;