import React from 'react';
import {
    Avatar,
    Button,
    Cell,
    Div,
    Group,
    Header,
    List,
    Panel,
    PanelHeader,
    Placeholder,
    Search,
    CardGrid,
    Card, Text
} from '@vkontakte/vkui';
import FeedListItem from '../components/FeedListItem';
import bridge from '@vkontakte/vk-bridge';
import Icon24Add from '@vkontakte/icons/dist/24/add';
import {AppSettingsContext} from '../app-settings-context';
import Icon56ServicesOutline from '@vkontakte/icons/dist/56/services_outline';
import Icon56UsersOutline from '@vkontakte/icons/dist/56/users_outline';
import Typography from "@material-ui/core/Typography";
import Skeleton from "@material-ui/lab/Skeleton";

class FeedList extends React.Component {
    static contextType = AppSettingsContext;

	constructor(props) {
        super(props);
        
        this.state = {
            items: null,
            search: "",
            user: {fio: "", imageUrl: '/account.png', statistic: {"all":"0","month":"0","year":"0"}},
            loading: true,
        }

        this.getItems = this.getItems.bind(this);
        this.handleAddToHomeScreenClick = this.handleAddToHomeScreenClick.bind(this);
        this.onSearchChange = this.onSearchChange.bind(this);
        this.getUserInfo = this.getUserInfo.bind(this);
    }
    
    componentDidMount() {
        this.getItems(); 
        this.getUserInfo();
    }

    getUserInfo(){
        fetch(this.context.domain + "/api/account?with_stat&" + window.location.search.substr(1))
            .then((res) => res.json())
            .then(
                (result) => {
                    if (result.status === "SUCCESS"){
                        this.setState({
                            user: result.data,
                        }, () => this.props.functions.newFeedItemsCountChange(result.data.unreadCount));
                    } else {
                        this.props.functions.showMessage(<Div>Некорректный пользователь. Перезапустите приложение.</Div>);
                    }
                    this.props.functions.changePopout(null);
                },
                (error) => {
                    /*TODO: Тут нужна обработка ошибки */
                }
            );
    }

    getItems() {
        fetch(this.context.domain + "/api/feed?" + window.location.search.substr(1))
        .then((res) => res.json())
        .then(
            (result) => {
                if (result.status === "SUCCESS"){
                    this.setState({
                        items: result.data,
                        loading: false,
                    });
                } else {
                    this.props.functions.showMessage(<Div>Некорректный пользователь. Перезапустите приложение.</Div>);
                }
                this.props.functions.changePopout(null);
            },
            (error) => {
                /*TODO: Тут нужна обработка ошибки */
            }
        ); 
    }

    handleAddToHomeScreenClick(){
        bridge.send("VKWebAppAddToHomeScreen").then(data => {
          /*TODO: Нужно что-то делать, если все хорошо? */
        })
        .catch(error => {
          /*TODO: Тут нужна обработка ошибки */
        });
    }

    onSearchChange(e){
        this.setState({ search: e.target.value });
    }

    doResizeImageUrl(image, x, y){
        return image.substring(0, image.lastIndexOf('.')) + '-' + x + 'x' + y + image.substring(image.lastIndexOf('.'));
    }

    getFontProps(num){
	    if (num < 10){
	        return {size: 'h1', marginTop: -6}
        } else if (num < 100) {
	        return {size: 'h2', marginTop: 13}
        } else {
	        return {size: 'h4', marginTop: 28}
        }
    }

	render() {
        let items = null;
        let itemsCount = this.state.items ? this.state.items.length : 0

        if (itemsCount){
            const search = this.state.search.toLowerCase();
            items = this.state.items
                .filter((item) => 
                        (
                            (item.review.book.name && item.review.book.name.toLowerCase().indexOf(search) > -1)
                                || (item.review.book.authorsString && item.review.book.authorsString.toLowerCase().indexOf(search) > -1)
                                || (item.review.tagList && item.review.tagList.toLowerCase().indexOf(search) > -1)
                            )
                    )
                .map((item) => {
                    return (
                        <FeedListItem key={item.review.id} data={item} onClick={() => this.props.functions.showReview(item.review.id, item.review.type)} />
                    );
                });

                if (!items || !items.length){
                    items = (
                        <Placeholder
                            icon={<Icon56ServicesOutline />}
                            header="Ничего не найдено"
                            action={<Button size="l" mode="secondary" stretched style={{ marginRight: 8 }} before={<Icon24Add/>} onClick={() => this.props.functions.showReviewEdit(0, 'finished')}>Добавить книгу</Button>}
                        >
                            Поменяйте параметры поиска!
                        </Placeholder>
                    );
                } else {
                    items = (
                        <React.Fragment key={items.length}>
                            <CardGrid>
                                {items}
                            </CardGrid>
                            <Div></Div>
                        </React.Fragment>
                    )
                }
        } else {
            items = (
                <Placeholder
                    icon={<Icon56UsersOutline />}
                    header="Никто еще ничего не опубликовал"
                    action={<Button size="l" mode="secondary" stretched style={{ marginRight: 8 }} before={<Icon24Add/>} onClick={() => this.props.functions.showReviewEdit(0, 'finished')}>Добавить книгу</Button>}
                >
                    Стань первым!
                </Placeholder>
            );
        }

        var imageWidth = Math.round((document.documentElement.clientWidth || document.body.clientWidth || window.innerWidth) / 10);
        var imageHeight = imageWidth;

        var sceletonWidth = Math.round((document.documentElement.clientWidth || document.body.clientWidth || window.innerWidth)*0.82);
        var sceletonHeight = Math.round(sceletonWidth / 2 * 3);
        var sceletonMargin = Math.round((document.documentElement.clientWidth || document.body.clientWidth || window.innerWidth)*0.05);

		return (
			<Panel id={this.props.id}>
                <PanelHeader>
                    Лента
                </PanelHeader>
                <Group separator="hide">
                    <List>
                        <Cell description={"Это вы"} before={ <Avatar size={imageWidth} src={this.context.domain + "/files" + this.doResizeImageUrl(this.state.user.imageUrl, imageWidth, imageHeight)} /> }>{this.state.user.fio}</Cell>
                    </List>
                </Group>
                <Group header={<Header mode="secondary">Прочитано</Header>}>
                    <CardGrid>
                        <Card size="s" mode="shadow" style={{textAlign: 'center', height: 150, backgroundColor: '#eaecef'}}>
                            <Typography variant="overline" display="block" gutterBottom>
                                За месяц
                            </Typography>
                            <Typography variant={this.getFontProps(this.state.user.statistic.month).size} component="h2" gutterBottom style={{marginTop: this.getFontProps(this.state.user.statistic.month).marginTop}}>
                                {new Intl.NumberFormat('ru-RU').format(this.state.user.statistic.month)}
                            </Typography>
                        </Card>
                        <Card size="s" mode="shadow" style={{textAlign: 'center', height: 150, backgroundColor: '#81cbff'}}>
                            <Typography variant="overline" display="block" gutterBottom>
                                За год
                            </Typography>
                            <Typography variant={this.getFontProps(this.state.user.statistic.year).size} gutterBottom style={{marginTop: this.getFontProps(this.state.user.statistic.year).marginTop}}>
                                {new Intl.NumberFormat('ru-RU').format(this.state.user.statistic.year)}
                            </Typography>
                        </Card>
                        <Card size="s" mode="shadow" style={{textAlign: 'center', height: 150, background: 'linear-gradient(45deg, #FF8E53 30%, #ffda53 90%)'}}>
                            <Typography variant="overline" display="block" gutterBottom>
                                Всего
                            </Typography>
                            <Typography variant={this.getFontProps(this.state.user.statistic.all).size} gutterBottom style={{marginTop: this.getFontProps(this.state.user.statistic.all).marginTop}}>
                                {new Intl.NumberFormat('ru-RU').format(this.state.user.statistic.all)}
                            </Typography>
                        </Card>
                    </CardGrid>
                </Group>
                <Group header={<Header mode="secondary">Что то новое</Header>}>
                    {
                        itemsCount > 0 &&
                        <Search value={this.state.search} onChange={this.onSearchChange} after={null} placeholder="Название, автор, тег"/>
                    }

                    {this.state.loading ?
                        <React.Fragment>
                            <CardGrid>
                                <Card size="l" mode="shadow">
                                    <Cell description={<Skeleton variant="text"/>}
                                          before={<Skeleton variant="circle" width={imageWidth} height={imageWidth}/>}><Skeleton
                                        variant="text"/></Cell>
                                    <Skeleton variant="rect" width={sceletonWidth} height={sceletonHeight}
                                              style={{margin: sceletonMargin}}/>
                                    <Div>
                                        <b><Skeleton variant="text"/></b>
                                        <Skeleton variant="text"/>
                                        <br/>
                                        <Typography variant="body2" gutterBottom>
                                            <Skeleton variant="text"/>
                                        </Typography>
                                        <Skeleton variant="text" width="80%"/>
                                    </Div>
                                </Card>
                            </CardGrid>
                            <Div></Div>
                        </React.Fragment>
                        :
                        items
                    }

                </Group>
            </Panel>
		);
	}
}

export default FeedList;