import React, { useContext } from 'react';
import {Button, Div, RichCell, Text, Card, Cell, CardGrid, UsersStack} from '@vkontakte/vkui';

import {AppSettingsContext} from '../app-settings-context';
import CardHeader from "@material-ui/core/CardHeader";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import CardActions from "@material-ui/core/CardActions";
import Collapse from "@material-ui/core/Collapse";
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { red } from '@material-ui/core/colors';
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 345,
        flexGrow: 1,
        overflow: 'hidden',
        padding: theme.spacing(0, 3),
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: red[500],
    },
    paper: {
        margin: `${theme.spacing(1)}px auto`,
        padding: theme.spacing(2),
    },
}));

// const  contextType = AppSettingsContext;

export default function  BookInClubWithReviewsListItem(props) {
const context = useContext(AppSettingsContext);
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);

    const  handleExpandClick = () => {
        setExpanded(!expanded);
    };


    const doResizeImageUrl = (image, x, y) =>{
        return image.substring(0, image.lastIndexOf('.')) + '-' + x + 'x' + y + image.substring(image.lastIndexOf('.'));
    }

    //render() {
        var bookWidth = Math.round((document.documentElement.clientWidth || document.body.clientWidth || window.innerWidth) / 2);
        var bookHeight = Math.round(bookWidth / 2 * 3);
        var avatarWidth = Math.round((document.documentElement.clientWidth || document.body.clientWidth || window.innerWidth) / 15);
        var avatarHeight = avatarWidth;
        var memberImageWidth = Math.round((document.documentElement.clientWidth || document.body.clientWidth || window.innerWidth) / 10);
        var memberImageHeight = memberImageWidth;
        var borderRadius = Math.round(avatarWidth / 20);

        return (
            /*<Card size="l" mode="shadow">
                <RichCell
                    multiline
                    before={<Div><img style={{ maxWidth: imageWidth, maxHeight: imageWidth*2, borderRadius: borderRadius }} src={this.context.domain + "/files" + this.doResizeImageUrl(this.props.data.book.imageUrl, imageWidth, imageHeight)} alt="" /></Div>}
                    text={<Text weight="regular" style={{ color: "gray"}}>{this.props.data.book.authorsString}</Text>}
                    caption={<Text weight="regular" style={{ color: "var(--text_secondary)", fontSize: 13, marginTop: 5}}>Ведущий: {this.props.data.master.fio}</Text>}
                    style={{padding: 0}}
                    after={this.props.data.reviewList && this.props.data.reviewList.map( review => {
                                return (
                                    review.user.fio
                                );
                            }
                        )}
                >
                    <b>{this.props.data.book.name}</b>
                </RichCell>
            </Card>*/
            <Card className={classes.root}>
            <Paper className={classes.paper}>
                <CardHeader
                    action={
                        <IconButton aria-label="settings">
                            <MoreVertIcon />
                        </IconButton>
                    }
                    title=<b>{props.data.book.name}</b>
                    subheader={props.data.book.authorsString}
                />
                <CardMedia
                    className={classes.media}
                    image={context.domain + "/files" + doResizeImageUrl(props.data.book.imageUrl, bookWidth, bookHeight)}
                    title={props.data.book.name}
                    style={{backgroundPosition: 'center', backgroundSize: 'auto'}}
                />
                <CardContent>
                    <Typography variant="body2" color="textSecondary" component="p">
                        {'Ведущий: ' + props.data.master.fio}
                        <br/>
                        <br/>
                        {props.data.comment}
                    </Typography>
                </CardContent>
                <CardActions disableSpacing>
                    <IconButton aria-label="add to favorites">
                        <FavoriteIcon />
                    </IconButton>
                    <IconButton aria-label="share">
                        <ShareIcon />
                    </IconButton>
                    {props.data.reviewList &&
                        <UsersStack
                            photos={props.data.reviewList.map((review) => {
                                return context.domain + "/files" + doResizeImageUrl(review.user.imageUrl, memberImageWidth, memberImageHeight)
                            })}
                            size="m"
                            visibleCount={5}
                            layout="vertical"
                            onClick={handleExpandClick}
                        />
                    }
                    <IconButton
                        className={clsx(classes.expand, {
                            [classes.expandOpen]: expanded,
                        })}
                        onClick={handleExpandClick}
                        aria-expanded={expanded}
                        aria-label="show more"
                    >
                        <ExpandMoreIcon />
                    </IconButton>
                </CardActions>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <CardContent>
                        {props.data.reviewList && props.data.reviewList.map( review => {
                                return (
                                    <React.Fragment>
                                        <Cell description={review.readDateStr} before={ <Avatar size={avatarWidth} src={context.domain + "/files" + doResizeImageUrl(review.user.imageUrl, avatarWidth, avatarHeight)} /> }>{review.user.fio}</Cell>
                                        {review.comment}
                                    </React.Fragment>
                                );
                            }
                        )}
                    </CardContent>
                </Collapse>
            </Paper>
            </Card>
        );
    //}
}

//export default BookInClubWithReviewsListItem;