import React from 'react';
import {
    Avatar,
    Button,
    Cell,
    Div,
    Group,
    Header,
    List,
    Panel,
    PanelHeader,
    Placeholder,
    Search,
    CardGrid,
    Card, RichCell
} from '@vkontakte/vkui';
import CollectionListItem from '../components/CollectionListItem';
import bridge from '@vkontakte/vk-bridge';
import Icon24Add from '@vkontakte/icons/dist/24/add';
import {AppSettingsContext} from '../app-settings-context';
import Icon56DocumentOutline from '@vkontakte/icons/dist/56/document_outline';
import Icon56ServicesOutline from '@vkontakte/icons/dist/56/services_outline';
import Skeleton from "@material-ui/lab/Skeleton";

class CollectionList extends React.Component {
    static contextType = AppSettingsContext;

	constructor(props) {
        super(props);
        
        this.state = {
            items: null,
            search: "",
            user: {fio: "", imageUrl: '/account.png'},
            loading: true,
        }

        this.getItems = this.getItems.bind(this);
        this.handleAddToHomeScreenClick = this.handleAddToHomeScreenClick.bind(this);
        this.onSearchChange = this.onSearchChange.bind(this);
        this.getUserInfo = this.getUserInfo.bind(this);
    }
    
    componentDidMount() {
        this.getItems(); 
        this.getUserInfo();
    }

    getUserInfo(){
        fetch(this.context.domain + "/api/account?" + window.location.search.substr(1))
            .then((res) => res.json())
            .then(
                (result) => {
                    if (result.status === "SUCCESS"){
                        this.setState({
                            user: result.data,
                        }, () => this.props.functions.newFeedItemsCountChange(result.data.unreadCount));
                    } else {
                        this.props.functions.showMessage(<Div>Некорректный пользователь. Перезапустите приложение.</Div>);
                    }
                    this.props.functions.changePopout(null);
                },
                (error) => {
                    /*TODO: Тут нужна обработка ошибки */
                }
            );
    }

    getItems() {
        fetch(this.context.domain + "/api/collection?list&" + window.location.search.substr(1))
        .then((res) => res.json())
        .then(
            (result) => {
                if (result.status === "SUCCESS"){
                    this.setState({
                        items: result.data,
                        loading: false,
                    });
                } else {
                    this.props.functions.showMessage(<Div>Некорректный пользователь. Перезапустите приложение.</Div>);
                }
                this.props.functions.changePopout(null);
            },
            (error) => {
                /*TODO: Тут нужна обработка ошибки */
            }
        ); 
    }

    handleAddToHomeScreenClick(){
        bridge.send("VKWebAppAddToHomeScreen").then(data => {
          /*TODO: Нужно что-то делать, если все хорошо? */
        })
        .catch(error => {
          /*TODO: Тут нужна обработка ошибки */
        });
    }

    onSearchChange(e){
        this.setState({ search: e.target.value });
    }

    doResizeImageUrl(image, x, y){
        return image.substring(0, image.lastIndexOf('.')) + '-' + x + 'x' + y + image.substring(image.lastIndexOf('.'));
    }

	render() {
        let items = null;
        let itemsCount = this.state.items ? this.state.items.length : 0

        if (itemsCount){
            const search = this.state.search.toLowerCase();
            items = this.state.items
                .filter((item) => 
                            (item.name && item.name.toLowerCase().indexOf(search) > -1) 
                            || (item.tagList && item.tagList.toLowerCase().indexOf(search) > -1)   
                    )
                .map((item) => {
                    return (
                        <CollectionListItem key={item.id} data={item} showCollection={this.props.functions.showCollection}/>
                    );
                });

                if (!items || !items.length){
                    items = (
                        <Placeholder
                            icon={<Icon56ServicesOutline />}
                            header="Ничего не найдено"
                            action={<Button size="l" mode="secondary" stretched style={{ marginRight: 8 }} before={<Icon24Add/>} onClick={() => this.props.functions.showCollectionEdit(0)}>Добавить подборку</Button>}
                        >
                            Поменяйте параметры поиска!
                        </Placeholder>
                    );
                } else {
                    items = (
                        <React.Fragment>
                            <CardGrid>
                                {items}
                            </CardGrid>
                            <Div></Div>
                        </React.Fragment>
                    )
                }
        } else {
            items = (
                <Placeholder
                    icon={<Icon56DocumentOutline />}
                    header="Пока ничего нет"
                    action={<Button size="l" mode="secondary" stretched style={{ marginRight: 8 }} before={<Icon24Add/>} onClick={() => this.props.functions.showCollectionEdit(0)}>Добавить подборку</Button>}
                >
                    Создайте новую подборку!
                </Placeholder>
            );
        }

        var imageWidth = Math.round((document.documentElement.clientWidth || document.body.clientWidth || window.innerWidth) / 10);
        var imageHeight = imageWidth;

        var sceletonWidth = Math.round((document.documentElement.clientWidth || document.body.clientWidth || window.innerWidth) / 5);
        var sceletonBorderRadius = Math.round(sceletonWidth / 20);

		return (
			<Panel id={this.props.id}>
                <PanelHeader>
                    Подборки
                </PanelHeader>
                <Group>
                    <List>
                        <Cell description={"Создано подборок: " + itemsCount} before={ <Avatar size={imageWidth} src={this.context.domain + "/files" + this.doResizeImageUrl(this.state.user.imageUrl, imageWidth, imageHeight)} /> }>{this.state.user.fio}</Cell>
                    </List>
                    <Div style={{display: 'flex'}}>
                        <Button size="l" stretched style={{ marginRight: 8 }} before={<Icon24Add/>} onClick={() => this.props.functions.showCollectionEdit(0)}>Добавить подборку</Button>
                        <Button size="l" stretched mode="secondary" onClick={this.handleAddToHomeScreenClick}>Ярлык</Button>
                    </Div>
                </Group>
                <Group header={<Header mode="secondary">Список</Header>}>
                    {
                        itemsCount > 0 &&
                        <Search value={this.state.search} onChange={this.onSearchChange} after={null} placeholder="Название, тег"/>
                    }

                    {this.state.loading ?
                        <React.Fragment>
                            <CardGrid>
                                {Array(5).fill(<Card size="l" mode="shadow">
                                    <RichCell
                                        multiline
                                        before={<Div><Skeleton variant="rect" width={sceletonWidth} height={sceletonWidth*3/2} style={{borderRadius: sceletonBorderRadius}} /></Div>}
                                        text={<Skeleton variant="text" width="70%"/>}
                                        caption={<Skeleton variant="text" width="60%"/>}
                                        after={<Div style={{color: "white", fontSize: 10}}>4</Div>}
                                        style={{padding: 0}}
                                    >
                                        <Skeleton variant="text" width="90%"/>
                                    </RichCell>
                                </Card>)}
                            </CardGrid>
                            <Div></Div>
                        </React.Fragment>
                        :
                        items
                    }

                </Group>
            </Panel>
		);
	}
}

export default CollectionList;