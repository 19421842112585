export default [
    { name: 'reviews', path: '/reviews', children: [
        { name: 'list', path: '/list'},
        { name: 'view', path: '/view/:id'},
        { name: 'edit', path: '/edit/:id'}
        ]},
    { name: 'message', path: '/message' },
    { name: 'collections', path: '/collections', children: [
        { name: 'list', path: '/list'},
        { name: 'view', path: '/view/:id'},
        { name: 'edit', path: '/edit/:id'}
        ]},
    { name: 'feed', path: '/feed' },
    { name: 'clubs', path: '/clubs', children: [
            { name: 'list', path: '/list'},
            { name: 'view', path: '/view/:id'},
            { name: 'edit', path: '/edit/:id'},
            { name: 'members', path: '/members/:id'},
            { name: 'book_in_club', path: '/:club_id/book/:book_in_club_id'}
        ]},
    { name: 'account', path: '/account', children: [
            { name: 'account', path: '/account'},
            { name: 'settings', path: '/settings'},
            { name: 'about', path: '/about'}
            ]},
]