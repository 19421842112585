import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import bridge from '@vkontakte/vk-bridge';
import { RouterProvider } from 'react-router5'
import createRouter from './create-router'

// Init VK App
bridge.send("VKWebAppInit", {});

const router = createRouter()

router.start(() => {
  ReactDOM.render((
      <RouterProvider router={router}>
          <App router={router}/>
      </RouterProvider>
  ), document.getElementById('root'))
})