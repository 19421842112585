import React from 'react';
import {
    Alert,
    Avatar,
    Button,
    Div,
    File,
    FixedLayout,
    FormLayout,
    FormLayoutGroup,
    Group,
    Header,
    InfoRow,
    Input,
    Link,
    Panel,
    PanelHeader,
    ScreenSpinner,
    SimpleCell,
    Snackbar,
    Tabs,
    TabsItem
} from '@vkontakte/vkui';
import {AppSettingsContext} from '../app-settings-context';
import Icon24Camera from '@vkontakte/icons/dist/24/camera';
import Icon16Done from '@vkontakte/icons/dist/16/done';
import Icon16CancelCircleOutline from '@vkontakte/icons/dist/16/cancel_circle_outline';

class Account extends React.Component {
    static contextType = AppSettingsContext;

    constructor(props) {
        super(props);

        this.state = {
            imageUrl: '/account.png',
            isLocalImage: false,
            name: '',
            surname: '',
            patronymic: '',
            snackBar: null,
            activeType: 'account',
        }

        this.getAccount = this.getAccount.bind(this);
        this.saveAccount = this.saveAccount.bind(this);
        this.deleteAccount = this.deleteAccount.bind(this);
        this.onImageChoose = this.onImageChoose.bind(this);
        this.showSnackBar = this.showSnackBar.bind(this);
    }

    componentDidMount() {
        this.getAccount();
    }

    getAccount() {
        fetch(this.context.domain + "/api/account?" + window.location.search.substr(1))
            .then((res) => res.json())
            .then(
                (result) => {
                    if (result.status === "SUCCESS"){
                        if (result.data){
                            this.setState({
                                imageUrl: result.data.imageUrl,
                                isLocalImage: false,
                                name: result.data.name,
                                surname: result.data.surname,
                                patronymic: result.data.patronymic,
                            }, () => this.props.functions.newFeedItemsCountChange(result.data.unreadCount));
                        }
                    } else {
                        this.showSnackBar("Ошибка: " + result.message, true);
                    }
                    this.props.functions.changePopout(null);
                },
                (error) => {
                    /*TODO: Тут нужна обработка ошибки */
                    this.props.functions.changePopout(null);
                    this.showSnackBar("Ошибка сервера. Попробуйте перезапустить приложение", true);
                }
            );
    }

    setObjectValue(object, fieldName, value){
        if (fieldName.includes('.')){ // Если это подобъект
            var innerObjectName = fieldName.split('.')[0];
            var otherNames = fieldName.substr(fieldName.indexOf('.') + 1, fieldName.length);
            var innerObject;
            if (object[innerObjectName]){
                innerObject = object[innerObjectName]
            } else {
                innerObject = {};
            }
            object[innerObjectName] = this.setObjectValue(innerObject, otherNames, value);
        } else { // Если это просто поле
            object[fieldName] = value;
        }
        return object;
    }

    saveAccount() {
        const formData = new FormData();

        // Соберем поля с name в json
        var item = {};
        var element_list = document.querySelectorAll('#account-form [name]');
        var element_array = [...element_list];
        element_array.forEach(element => {
            var elName = element.getAttribute('name');
            this.setObjectValue(item, elName, element.value)
        });

        formData.append('accountJson', JSON.stringify(item));
        if (this.state.isLocalImage){
            formData.append('image', this.state.imageUrl); // fileField.files[0]
        }

        fetch(this.context.domain + "/api/account?save&" + window.location.search.substr(1), {
            method: 'POST',
            body: formData
        }).then((res) => res.json())
            .then(
                (result) => {
                    if (result.status === "SUCCESS"){
                        if (result.data){
                            this.showSnackBar("Успешно сохранено!", false);
                        } else {
                            this.showSnackBar("Ошибка: " + result.message, true);
                        }
                    } else {
                        this.showSnackBar("Ошибка: " + result.message, true);
                    }
                    this.props.functions.changePopout(null);
                },
                (error) => {
                    /*TODO: Тут нужна обработка ошибки */
                    console.error('Ошибка:', error);
                    this.props.functions.changePopout(null);
                }
            );
    }

    deleteAccount(){
        this.props.functions.changePopout(<Alert
            actions={[{
                title: 'Отмена',
                autoclose: true,
                mode: 'cancel'
            }, {
                title: 'Удалить все данные',
                autoclose: true,
                action: () => {
                    this.props.functions.changePopout(<ScreenSpinner />);
                    fetch(this.context.domain + "/api/account?delete&" + window.location.search.substr(1), {
                        method: 'POST'
                    }).then((res) => res.json())
                        .then(
                            (result) => {
                                if (result.status === "SUCCESS"){
                                    this.showSnackBar("Ваши данные удалены", false);
                                } else {
                                    this.showSnackBar("Ошибка: " + result.message, true);
                                }
                                this.props.functions.changePopout(null);
                            },
                            (error) => {
                                /*TODO: Тут нужна обработка ошибки */
                                this.props.functions.changePopout(null);
                                this.showSnackBar("Ошибка сервера. Попробуйте перезапустить приложение", true);
                            }
                        )
                },
            }]}
            onClose={() => this.props.functions.changePopout(null)}
        >
            <h2>Подтвердите действие</h2>
            <p>Удалятся все книги, подборки и другие данные, их нельзя будет восстановить. Вы уверены, что хотите удалить?</p>
        </Alert>)
    }

    doResizeImageUrl(image, x, y){
        return image.substring(0, image.lastIndexOf('.')) + '-' + x + 'x' + y + image.substring(image.lastIndexOf('.'));
    }

    onImageChoose(event){
        var that = this;
        var element = event.target;
        var files = element.files;
        for (var i = 0, f; f = files[i]; i++) {
            var reader = new FileReader();
            reader.onload = (function (f) {
                return function (e) {
                    that.props.functions.showPhotoEdit(
                        e.target.result
                        , (imageUrl)=>{
                            if (imageUrl){
                                that.setState({imageUrl: imageUrl, isLocalImage: true,})
                            }
                        })
                };
            })(f);
            reader.readAsDataURL(f);
        }
    }

    showSnackBar (message, isError = false) {
        if (this.state.snackbar) return;
        this.setState({ snackbar:
                <Snackbar
                    layout="vertical"
                    onClose={() => this.setState({ snackbar: null })}
                    before={isError ? <Avatar size={24} style={{backgroundColor: 'var(--dynamic_red)'}}><Icon16CancelCircleOutline fill="#fff" width={14} height={14} /></Avatar>
                        :<Avatar size={24} style={{backgroundColor: 'var(--accent)'}}><Icon16Done fill="#fff" width={14} height={14} /></Avatar>}
                >
                    {message}
                </Snackbar>
        });
    }

    render() {
        var imageWidth = Math.round((document.documentElement.clientWidth || document.body.clientWidth || window.innerWidth) / 5);
        var imageHeight = imageWidth;
        var borderRadius = Math.round(imageWidth / 20);

        return (
            <Panel id={this.props.id}>
                <PanelHeader>
                    Аккаунт
                </PanelHeader>
                <Tabs>
                    <TabsItem
                        onClick={() => this.setState({activeType: 'account'})}
                        selected={this.state.activeType === 'account'}
                    >
                        Аккаунт
                    </TabsItem>
                    <TabsItem
                        onClick={() => this.setState({activeType: 'about'})}
                        selected={this.state.activeType === 'about'}
                    >
                        О приложении
                    </TabsItem>
                </Tabs>
                {this.state.activeType === 'account' &&
                    <React.Fragment>
                        <FormLayout id="account-form">
                            <FormLayoutGroup top={<Header mode="secondary">Фото</Header>}>
                                <SimpleCell disabled before={<img style={{ marginLeft: 12, maxWidth: imageWidth, maxHeight: imageWidth*1.1, borderRadius: borderRadius }} src={this.state.isLocalImage ? this.state.imageUrl : this.context.domain + "/files" + this.doResizeImageUrl(this.state.imageUrl, imageWidth, imageHeight)} alt="" />}>
                                    <File id="account-image" before={<Icon24Camera />} size="l" mode="secondary" onChange={this.onImageChoose}>Выбрать из галереи</File>
                                </SimpleCell>
                            </FormLayoutGroup>
                            <Input type="text" name="surname" defaultValue={this.state.surname} placeholder="Фамилия"/>
                            <Input type="text" name="name" defaultValue={this.state.name} placeholder="Имя"/>
                            <Input type="text" name="patronymic" defaultValue={this.state.patronymic} placeholder="Отчество"/>
                        </FormLayout>
                        <Div style={{display: 'flex'}}>
                            <Button mode="tertiary" stretched onClick={this.deleteAccount} style={{color: "red"}}>Удалить все данные</Button>
                        </Div>
                        <FixedLayout vertical="bottom">
                            <Div style={{display: 'flex'}}>
                                <Button stretched onClick={()=>this.saveAccount()}>Сохранить</Button>
                            </Div>
                        </FixedLayout>
                    </React.Fragment>
                }
                {this.state.activeType === 'about' &&
                <React.Fragment>
                    <Group>
                        <Header mode="secondary">Приложение</Header>
                        <SimpleCell multiline>
                            <InfoRow header="Дата последнего обновления">
                                8 августа 2020г.
                            </InfoRow>
                        </SimpleCell>
                        <SimpleCell>
                            <InfoRow header="Версия">
                                0.4.0
                            </InfoRow>
                        </SimpleCell>
                    </Group>
                    <Group>
                        <Header mode="secondary">Разработчик</Header>
                        <SimpleCell multiline>
                            <InfoRow header="Авторы">
                                <Link href='https://vk.com/cool_id' target="_blank">Ямашев Адель</Link>
                            </InfoRow>
                        </SimpleCell>
                        <SimpleCell>
                            <InfoRow header="Компания">
                                <Link href='https://www.itbricksoft.com/index.htm' target="_blank">IT Brick</Link>
                            </InfoRow>
                        </SimpleCell>
                    </Group>
                </React.Fragment>
                }
                {this.state.snackbar}
            </Panel>
        );
    }
}

export default Account;