import React from 'react';
import {Card, Div, RichCell, Text} from '@vkontakte/vkui';
import {AppSettingsContext} from '../app-settings-context';

class ClubListItem extends React.Component {
    static contextType = AppSettingsContext;

    doResizeImageUrl(image, x, y){
        return image.substring(0, image.lastIndexOf('.')) + '-' + x + 'x' + y + image.substring(image.lastIndexOf('.'));
    }

    render() {
        var imageWidth = Math.round((document.documentElement.clientWidth || document.body.clientWidth || window.innerWidth) / 5);
        var imageHeight = Math.round(imageWidth / 2 * 3);
        var borderRadius = Math.round(imageWidth / 20);
        
        return (
            <Card size="l" mode="shadow">
                <RichCell
                    multiline
                    before={<Div><img style={{ maxWidth: imageWidth, maxHeight: imageWidth*2, borderRadius: borderRadius }} src={this.context.domain + "/files" + this.doResizeImageUrl(this.props.data.imageUrl, imageWidth, imageHeight)} alt="" /></Div>}
                    text={<Text weight="regular" style={{ color: "gray"}}>5 участников</Text>}
                    caption={<Text weight="regular" style={{ color: "var(--text_secondary)", fontSize: 13, marginTop: 5}}>создан {this.props.data.addedDateTimeStr}</Text>}
                    onClick={() => this.props.onClick(this.props.data.id)}
                    style={{padding: 0}}
                    >
                    <b>{this.props.data.name}</b>
                </RichCell>
            </Card>
        );
    }
  }

  export default ClubListItem;