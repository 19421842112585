import React from 'react';
import {
    Panel, PanelHeaderButton, PanelHeader, Div, FixedLayout
    , FormLayout, File, Avatar, SimpleCell, Header, Cell, Switch, Snackbar
    , FormLayoutGroup, Input, Textarea, Button, Placeholder, Search, Group, CardGrid, ScreenSpinner
} from '@vkontakte/vkui';
import Icon24BrowserBack from '@vkontakte/icons/dist/24/browser_back';
import Icon24Camera from '@vkontakte/icons/dist/24/camera';
import {AppSettingsContext} from '../app-settings-context';
import Icon16Done from '@vkontakte/icons/dist/16/done';
import Icon16CancelCircleOutline from '@vkontakte/icons/dist/16/cancel_circle_outline';
import ReviewListItem from "../components/ReviewListItem";
import Icon56InboxOutline from '@vkontakte/icons/dist/56/inbox_outline';
import Icon56ServicesOutline from '@vkontakte/icons/dist/56/services_outline';
import Icon24Add from '@vkontakte/icons/dist/24/add';
import MultipleChipAutoCompleteField from "../components/MultipleChipAutoCompleteField";

class CollectionEdit extends React.Component {
    static contextType = AppSettingsContext;

	constructor(props) {
        super(props);

        this.state ={
            id: props.route.params.id,
            imageUrl: "/collection.png",
            isLocalImage: false,
            name: null,
            addedDateTimeStr: null,
            comment: "",
            public: false,
            tagList: '',
            reviewList: null,
            search: "",
            snackbar: null,
        }

        this.getCollection = this.getCollection.bind(this);
        this.saveCollection = this.saveCollection.bind(this);
        this.showSnackBar = this.showSnackBar.bind(this);
        this.onImageChoose = this.onImageChoose.bind(this);
        this.commentChange = this.commentChange.bind(this);
        this.onSearchChange = this.onSearchChange.bind(this);
        this.onBookAdd = this.onBookAdd.bind(this);
        this.onBookDelete = this.onBookDelete.bind(this);
        this.addTag = this.addTag.bind(this);
	}

    componentDidMount() {
        this.getCollection();
    }

    getCollection() {
        if (this.state.id){
            fetch(this.context.domain + "/api/collection?id=" + this.state.id + "&" + window.location.search.substr(1))
            .then((res) => res.json())
            .then(
                (result) => {
                    if (result.status === "SUCCESS"){
                        if (result.data){
                            if (result.data.canEdit) {
                                this.setState({
                                    id: result.data.id,
                                    name: result.data.name,
                                    imageUrl: result.data.imageUrl,
                                    isLocalImage: false,
                                    addedDateTimeStr: result.data.addedDateTimeStr,
                                    comment: result.data.comment,
                                    public: result.data.public,
                                    tagList: result.data.tagList,
                                    reviewList: result.data.reviewList,
                                });
                            } else {
                                this.props.functions.showCollection(result.data.id)
                            }
                        } else {
                            this.setState({
                                id: 0,
                            });
                        }
                    } else {
                        this.showSnackBar("Ошибка: " + result.message, true);
                    }
                    this.props.functions.changePopout(null);
                },
                (error) => {
                    /*TODO: Тут нужна обработка ошибки */
                    this.props.functions.changePopout(null);
                    this.showSnackBar("Ошибка сервера. Попробуйте перезапустить приложение", true);
                }
            ); 
        } else {
            this.props.functions.changePopout(null);
        }
    }

    setObjectValue(object, fieldName, value){
        if (fieldName.includes('.')){ // Если это подобъект
            var innerObjectName = fieldName.split('.')[0];
            var otherNames = fieldName.substr(fieldName.indexOf('.') + 1, fieldName.length);
            var innerObject;
            if (object[innerObjectName]){
                innerObject = object[innerObjectName]
            } else {
                innerObject = {};
            }
            object[innerObjectName] = this.setObjectValue(innerObject, otherNames, value);
        } else { // Если это просто поле
            object[fieldName] = value;
        }
        return object;
    }

    saveCollection() {
        const formData = new FormData();

        // Соберем поля с name в json
        var item = {};
        var element_list = document.querySelectorAll('#collection-form [name]');
        var element_array = [...element_list];
        element_array.forEach(element => {
            var elName = element.getAttribute('name');
            this.setObjectValue(item, elName, element.value)
        });

        item.public = this.state.public
        item.reviewList = []
        if (this.state.reviewList) {
            this.state.reviewList.forEach((review) => {
                item.reviewList.push({id: review.id})
            })
        }

        formData.append('collectionJson', JSON.stringify(item));
        if (this.state.isLocalImage){
            formData.append('image', this.state.imageUrl); 
        }

        this.props.functions.changePopout(<ScreenSpinner />);

        fetch(this.context.domain + "/api/collection?save&" + window.location.search.substr(1), {
            method: 'POST',
            body: formData
        }).then((res) => res.json())
        .then(
            (result) => {
                if (result.status === "SUCCESS"){
                    if (result.data){
                        this.props.functions.showCollection(result.data.id)
                    } else {
                        this.showSnackBar("Ошибка: " + result.message, true);
                    }
                } else {
                    this.showSnackBar("Ошибка: " + result.message, true);
                }
                this.props.functions.changePopout(null);
            },
            (error) => {
                /*TODO: Тут нужна обработка ошибки */
                console.error('Ошибка:', error);
                this.props.functions.changePopout(null);
            }
        );
    }

    showSnackBar (message, isError = false) {
        if (this.state.snackbar) return;
        this.setState({ snackbar:
          <Snackbar
            layout="vertical"
            onClose={() => this.setState({ snackbar: null })}
            before={isError ? <Avatar size={24} style={{backgroundColor: 'var(--dynamic_red)'}}><Icon16CancelCircleOutline fill="#fff" width={14} height={14} /></Avatar>
                        :<Avatar size={24} style={{backgroundColor: 'var(--accent)'}}><Icon16Done fill="#fff" width={14} height={14} /></Avatar>}
          >
            {message}
          </Snackbar>
        });
    }

    doResizeImageUrl(image, x, y){
        return image.substring(0, image.lastIndexOf('.')) + '-' + x + 'x' + y + image.substring(image.lastIndexOf('.'));
    }

    onImageChoose(event){
        var that = this;
        var element = event.target;
        var files = element.files;
        for (var i = 0, f; f = files[i]; i++) {   
            var reader = new FileReader();
            reader.onload = (function (f) {
                return function (e) {
                    that.props.functions.showPhotoEdit(
                        e.target.result
                        , (imageUrl)=>{
                                if (imageUrl){
                                    that.setState({imageUrl: imageUrl, isLocalImage: true,})
                                }
                            })
                };
            })(f);
            reader.readAsDataURL(f);
        }
    }

    commentChange(event){
        this.setState({comment: event.target.value});
    }

    onSearchChange(e){
        this.setState({ search: e.target.value });
    }

    onBookAdd(){
        this.props.functions.showChooseReview(this.state.reviewList, (review) => {
            if (review) {
                this.setState(prevState => ({
                     reviewList: prevState.reviewList ? [...prevState.reviewList, review] : [review],
                }))
            }
        })
    }

    onBookDelete(id){
        this.setState(prevState => ({
            reviewList: prevState.reviewList.filter((item) => {return item.id !== id}),
        }))
    }

    addTag(event){
        if(event.key === 'Enter') {
            let obj = event.target
            this.setState((state, props) => ({
                    tagList: state.tagList ? state.tagList + ' ' + obj.value : obj.value
                }),
                () => {
                    obj.value = ''
                }
            );
        }
    }

	render() {
        var imageWidth = Math.round((document.documentElement.clientWidth || document.body.clientWidth || window.innerWidth) / 5);
        var imageHeight = Math.round(imageWidth / 2 * 3);
        var borderRadius = Math.round(imageWidth / 20);

        let items = null;
        var reviewsCount = this.state.reviewList ? this.state.reviewList.length : 0
        if (reviewsCount){
            const search = this.state.search.toLowerCase();
            items = this.state.reviewList
                .filter((item) =>
                    (
                        (item.book.name && item.book.name.toLowerCase().indexOf(search) > -1)
                        || (item.book.authorsString && item.book.authorsString.toLowerCase().indexOf(search) > -1)
                        || (item.tagList && item.tagList.toLowerCase().indexOf(search) > -1)
                    )
                )
                .map((item) => {
                    return (
                        <ReviewListItem key={item.id} data={item} onClick={()=>{}} onDelete={this.onBookDelete}/>
                    );
                });

            if (!items || !items.length){
                items = (
                    <Placeholder
                        icon={<Icon56ServicesOutline />}
                        header="Ничего не найдено"
                    >
                        Поменяйте параметры поиска!
                    </Placeholder>
                );
            } else {
                items = (
                    <CardGrid>
                        {items}
                    </CardGrid>
                )
            }
        } else {
            items = (
                <Placeholder
                    icon={<Icon56InboxOutline />}
                    header="Пока ничего нет"
                >
                    В подборке еще нет книг
                </Placeholder>
            );
        }

		return (
			<Panel id={this.props.id}>
                <PanelHeader left={<PanelHeaderButton><Icon24BrowserBack onClick={() => {this.state.id ? this.props.functions.showCollection(this.state.id) : this.props.functions.showCollectionList()}}/></PanelHeaderButton>}>
                    {this.state.id ? "Редактировать подборку": "Добавить подборку"}
                </PanelHeader>
                <FormLayout id="collection-form">
                    <FormLayoutGroup top={<Header mode="secondary">Фото для обложки</Header>}>
                        <SimpleCell disabled before={<img style={{ marginLeft: 12, maxWidth: imageWidth, maxHeight: imageWidth*2, borderRadius: borderRadius }} src={this.state.isLocalImage ? this.state.imageUrl : this.context.domain + "/files" + this.doResizeImageUrl(this.state.imageUrl, imageWidth, imageHeight)} alt="" />}>
                            <File id="collection-image" before={<Icon24Camera />} size="l" mode="secondary" onChange={this.onImageChoose}>Выбрать из галереи</File>    
                        </SimpleCell>
                    </FormLayoutGroup>
                    <Input type="text" name="name" defaultValue={this.state.name} placeholder="Наименование" top="Наименование" />
                    <Textarea top="Описание подборки" name="comment" placeholder="Здесь описане подборки..." style={{minHeight: "150px"}} value={this.state.comment} onChange={this.commentChange}/>
                    <Cell asideContent={<Switch name="public" checked={this.state.public} onChange={()=>this.setState({public: !this.state.public})} />}>
                        Опубликовать в открытый доступ
                    </Cell>
                    <Div><MultipleChipAutoCompleteField url={this.context.domain + "/api/tag?autocomplete="}
                                                        id="tagList"
                                                        label="Теги"
                                                        value={this.state.tagList ? this.state.tagList.split(' ').map((item) => {return {name: item}}) : ''}
                                                        onChange={(value) => {
                                                            this.setState({tagList: value.map(item => item.name).join(' ')})
                                                        }}
                                                        getOptionLabel={(option) => option.name}
                    /></Div>
                    <Group header={<Header mode="secondary">Книги</Header>}>
                        {
                            reviewsCount > 0 &&
                            <Search value={this.state.search} onChange={this.onSearchChange} placeholder="Название, автор, тег"/>
                        }
                        {items}
                        <Div style={{display: 'flex'}}>
                            <Button size="l" mode="secondary" stretched style={{ marginRight: 8 }} before={<Icon24Add/>} onClick={this.onBookAdd}>Добавить книгу</Button>
                        </Div>
                    </Group>
                    <FixedLayout vertical="bottom">
                        <Div style={{display: 'flex'}}>
                            <Button stretched onClick={()=>this.saveCollection()}>Сохранить</Button>
                        </Div>
                    </FixedLayout>
                    <input type="hidden" name="id" defaultValue={this.state.id}/>
                    <input type="hidden" name="tagList" value={this.state.tagList}/>
                    {this.state.snackbar}
                </FormLayout>
            </Panel>
		);
	}
}

export default CollectionEdit;