import React from 'react';
import {
    Avatar,
    Button,
    Div,
    File,
    FixedLayout,
    FormLayout,
    FormLayoutGroup,
    Header,
    Input,
    Panel,
    PanelHeader,
    PanelHeaderButton, ScreenSpinner,
    SimpleCell,
    Snackbar,
    Textarea
} from '@vkontakte/vkui';
import Icon24BrowserBack from '@vkontakte/icons/dist/24/browser_back';
import Icon24Camera from '@vkontakte/icons/dist/24/camera';
import {AppSettingsContext} from '../app-settings-context';
import Icon24Add from '@vkontakte/icons/dist/24/add';
import Icon24DismissSubstract from '@vkontakte/icons/dist/24/dismiss_substract';
import Icon16Done from '@vkontakte/icons/dist/16/done';
import Icon16CancelCircleOutline from '@vkontakte/icons/dist/16/cancel_circle_outline';
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton";
import AutoCompleteField from "../components/AutoCompleteField";
import MultipleChipAutoCompleteField from "../components/MultipleChipAutoCompleteField";
import Rating from "@material-ui/lab/Rating";
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {KeyboardDatePicker, MuiPickersUtilsProvider,} from '@material-ui/pickers';
import ruLocale from "date-fns/locale/ru";
import Typography from "@material-ui/core/Typography";

class ReviewEdit extends React.Component {
    static contextType = AppSettingsContext;

	constructor(props) {
        super(props);

        this.state ={
            id: props.route.params.id,
            bookId: 0,
            imageUrl: "/book.png",
            isLocalImage: false,
            name: null,
            authorList: [{id: -new Date().getTime()}],
            addedDateTimeStr: null,
            readDate: null,
            rating: 4,
            comment: "",
            public: false,
            tagList: '',
            type: this.props.activeReviewType,
            snackbar: null,
        }

        this.getReview = this.getReview.bind(this);
        this.saveReview = this.saveReview.bind(this);
        this.addAuthor = this.addAuthor.bind(this);
        this.deleteAuthor = this.deleteAuthor.bind(this);
        this.showSnackBar = this.showSnackBar.bind(this);
        this.onImageChoose = this.onImageChoose.bind(this);
        this.commentChange = this.commentChange.bind(this);
        this.addTag = this.addTag.bind(this);
	}

    componentDidMount() {
        this.getReview();
    }

    strToDate(str){
	    if (!str){
	        return null
        }
	    let parts = str.split('.')
	    return new Date(parts[2], parts[1] - 1, parts[0]);
    }

    toTwoDigit(src){
	    return src <= 9 ? '0' + src : src
    }

    dateToStr(date){
        if (!date){
            return null
        }
        return this.toTwoDigit(date.getDate()) + '.' + this.toTwoDigit(parseInt(date.getMonth()) + 1) + '.' + date.getFullYear();
    }

    getReview() {
        if (this.state.id){
            fetch(this.context.domain + "/api/review?id=" + this.state.id + "&" + window.location.search.substr(1))
            .then((res) => res.json())
            .then(
                (result) => {
                    if (result.status === "SUCCESS"){
                        if (result.data){
                            if (result.data.canEdit) {
                                this.setState({
                                    id: result.data.id,
                                    bookId: result.data.book.id,
                                    name: result.data.book.name,
                                    imageUrl: result.data.imageUrl,
                                    isLocalImage: false,
                                    authorList: result.data.book.authorList,
                                    addedDateTimeStr: result.data.addedDateTimeStr,
                                    readDate: this.strToDate(result.data.readDateStr),
                                    rating: result.data.rating,
                                    comment: result.data.comment,
                                    public: result.data.public,
                                    tagList: result.data.tagList,
                                    type: result.data.type,
                                });
                            } else {
                                this.props.functions.showReview(result.data.id, this.state.type)
                            }
                        } else {
                            this.setState({
                                id: 0,
                            });
                        }
                    } else {
                        this.showSnackBar("Ошибка: " + result.message, true);
                    }
                    this.props.functions.changePopout(null);
                },
                (error) => {
                    /*TODO: Тут нужна обработка ошибки */
                    this.props.functions.changePopout(null);
                    this.showSnackBar("Ошибка сервера. Попробуйте перезапустить приложение", true);
                }
            ); 
        } else {
            this.props.functions.changePopout(null);
        }
    }

    setObjectValue(object, fieldName, value){
        if (fieldName.includes('.')){ // Если это подобъект
            var innerObjectName = fieldName.split('.')[0];
            var otherNames = fieldName.substr(fieldName.indexOf('.') + 1, fieldName.length);
            var innerObject;
            if (object[innerObjectName]){
                innerObject = object[innerObjectName]
            } else {
                innerObject = {};
            }
            object[innerObjectName] = this.setObjectValue(innerObject, otherNames, value);
        } else { // Если это просто поле
            object[fieldName] = value;
        }
        return object;
    }

    saveReview() {
        const formData = new FormData();

        // Соберем поля с name в json
        var item = {};
        var element_list = document.querySelectorAll('#review-form [name]');
        var element_array = [...element_list];
        element_array.forEach(element => {
            var elName = element.getAttribute('name');
            this.setObjectValue(item, elName, element.value)
        });

        item.public = this.state.public

        // Соберем и добавим авторов
        element_list = document.querySelectorAll('#review-form [field-name="author"]');
        element_array = [...element_list];
        var authorList = [];
        element_array.forEach(element => {
            var author = {}
            author.name = element.querySelector('[field-name="author.name"]').value;
            author.surname = element.querySelector('[field-name="author.surname"]').value;
            author.patronymic = element.querySelector('[field-name="author.patronymic"]').value;
            authorList.push(author);
        });       
        item.book.authorList = authorList;
        item.readDateStr = this.dateToStr(this.state.readDate)
        if (this.state.isLocalImage){
            item.book.imageBase64String = this.state.imageUrl
        }

        formData.append('reviewJson', JSON.stringify(item));

        this.props.functions.changePopout(<ScreenSpinner />);
        fetch(this.context.domain + "/api/review?save&" + window.location.search.substr(1), {
            method: 'POST',
            body: formData
        }).then((res) => res.json())
        .then(
            (result) => {
                if (result.status === "SUCCESS"){
                    if (result.data){
                        this.setState({
                            id: result.data.id
                        }, () => {
                            //this.getAccount();
                            //this.showSnackBar("Сохранено!");
                            this.props.functions.showReview(result.data.id, result.data.type)
                        });
                    } else {
                        this.showSnackBar("Ошибка: " + result.message, true);
                    }
                } else {
                    this.showSnackBar("Ошибка: " + result.message, true);
                }
                this.props.functions.changePopout(null);
            },
            (error) => {
                /*TODO: Тут нужна обработка ошибки */
                console.error('Ошибка:', error);
                this.props.functions.changePopout(null);
            }
        );
    }

    addAuthor(){
        this.setState({
            authorList: this.state.authorList.concat({id: -new Date().getMilliseconds()}),
        });
    }

    deleteAuthor(id){
        this.setState({
            authorList: this.state.authorList.filter((item) => item.id !== id),
        });
    }

    showSnackBar (message, isError = false) {
        if (this.state.snackbar) return;
        this.setState({ snackbar:
          <Snackbar
            layout="vertical"
            onClose={() => this.setState({ snackbar: null })}
            before={isError ? <Avatar size={24} style={{backgroundColor: 'var(--dynamic_red)'}}><Icon16CancelCircleOutline fill="#fff" width={14} height={14} /></Avatar>
                        :<Avatar size={24} style={{backgroundColor: 'var(--accent)'}}><Icon16Done fill="#fff" width={14} height={14} /></Avatar>}
          >
            {message}
          </Snackbar>
        });
    }

    doResizeImageUrl(image, x, y){
        return image.substring(0, image.lastIndexOf('.')) + '-' + x + 'x' + y + image.substring(image.lastIndexOf('.'));
    }

    onImageChoose(event){
        var that = this;
        var element = event.target;
        var files = element.files;
        for (var i = 0, f; f = files[i]; i++) {   
            var reader = new FileReader();
            reader.onload = (function (f) {
                return function (e) {
                    that.props.functions.showPhotoEdit(
                        e.target.result
                        , (imageUrl)=>{
                                if (imageUrl){
                                    that.setState({imageUrl: imageUrl, isLocalImage: true,})
                                }
                            })
                };
            })(f);
            reader.readAsDataURL(f);
        }
    }

    commentChange(event){
        this.setState({comment: event.target.value});
    }

    addTag(event){
        if(event.key === 'Enter') {
            let obj = event.target
            this.setState((state, props) => ({
                tagList: state.tagList ? state.tagList + ' ' + obj.value : obj.value
            }),
                () => {
                    obj.value = ''
                }
            );
        }
    }

	render() {
        var imageWidth = Math.round((document.documentElement.clientWidth || document.body.clientWidth || window.innerWidth) / 5);
        var imageHeight = Math.round(imageWidth / 2 * 3);
        var borderRadius = Math.round(imageWidth / 20);

		return (
			<Panel id={this.props.id}>
                <PanelHeader left={<PanelHeaderButton><Icon24BrowserBack onClick={() => {this.state.id ? this.props.functions.showReview(this.state.id) : this.props.functions.showReviewList(this.state.type)}}/></PanelHeaderButton>}>
                    {this.state.id ? "Редактировать книгу": "Добавить книгу"}
                </PanelHeader>
                <FormLayout id="review-form">
                    <FormLayoutGroup top={<Header mode="secondary">Фото для обложки</Header>}>
                        <SimpleCell disabled before={<img style={{ marginLeft: 12, maxWidth: imageWidth, maxHeight: imageWidth*2, borderRadius: borderRadius }} src={this.state.isLocalImage ? this.state.imageUrl : this.context.domain + "/files" + this.doResizeImageUrl(this.state.imageUrl, imageWidth, imageHeight)} alt="" />}>
                            <File id="review-image" before={<Icon24Camera />} size="l" mode="secondary" onChange={this.onImageChoose}>Выбрать из галереи</File>    
                        </SimpleCell>
                    </FormLayoutGroup>
                    <Div>
                        <AutoCompleteField url={this.context.domain + "/api/book?autocomplete="}
                                           id="book.name"
                                           label="Наименование"
                                           value={this.state.name}
                                           onChange={(value, valueObject) => {
                                               if (valueObject){
                                                   const newList = valueObject.authorList.slice();
                                                   this.setState({
                                                       authorList: newList,
                                                       imageUrl: valueObject.imageUrl,
                                                       name: valueObject.name,
                                                   })
                                               } else {
                                                   this.setState({name: value})
                                               }
                                           }}
                                           getOptionLabel={(option) => option.name + ' (' + option.authorsString + ')'}
                                           name="book.name"
                        />
                    </Div>
                    {
                        this.state.authorList && 
                            this.state.authorList.map((author, index) => {
                                return (
                                    <FormLayoutGroup field-name="author" key={author.id} top="Автор" style={{borderWidth: "2px", borderStyle: "dashed", borderColor: "lightgray", padding: "5px"}}>
                                        <input type="hidden" field-name="author.id" value={author.id}/>
                                        <Div><AutoCompleteField url={this.context.domain + "/api/author?autocomplete="}
                                                                id="author.surname"
                                                                label="Фамилия"
                                                                value={author.surname}
                                                                onChange={(value, valueObject) => {
                                                                    const newList = this.state.authorList.slice();
                                                                    let newAuthor = Object.assign({}, author)
                                                                    if (valueObject){
                                                                        newAuthor.name = valueObject.name
                                                                        newAuthor.surname = valueObject.surname
                                                                        newAuthor.patronymic = valueObject.patronymic
                                                                    } else {
                                                                        newAuthor.surname = value
                                                                    }
                                                                    newList[index] = newAuthor
                                                                    this.setState({authorList: newList})
                                                                }}
                                                                getOptionLabel={(option) => option.fio}
                                                                field-name="author.surname"
                                        /></Div>
                                        <Input type="text" field-name="author.name" defaultValue={author.name} placeholder="Имя"/>
                                        <Input type="text" field-name="author.patronymic" defaultValue={author.patronymic} placeholder="Отчество"/>
                                        {
                                            index > 0 && (
                                                <Div style={{textAlign: 'right'}}>
                                                    <Button mode="destructive" align="right" onClick={() => this.deleteAuthor(author.id)} before={<Icon24DismissSubstract/>}>Удалить автора</Button>
                                                </Div>
                                            )
                                        }
                                    </FormLayoutGroup>
                                );
                            })
                    }
                    <Div style={{display: 'flex'}}>
                        <Button mode="secondary" onClick={this.addAuthor} before={<Icon24Add></Icon24Add>}>Добавить автора</Button>
                    </Div>
                    <Div>
                        <Typography variant="subtitle2" display="block" gutterBottom style={{color: '#818c99'}}>
                            Рейтинг
                        </Typography>
                        <Rating
                            name={"rating"}
                            value={Number(this.state.rating)}
                            onChange={(event, newValue) => {
                                this.setState({rating: newValue})
                            }}
                        />
                    </Div>
                    {this.state.type == 'finished' &&
                        <Div>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ruLocale}>
                                <KeyboardDatePicker
                                    id="date-picker-dialog"
                                    label="Дата прочтения"
                                    format="dd.MM.yyyy"
                                    value={this.state.readDate}
                                    onChange={(date) => {
                                        this.setState({readDate: date})
                                    }}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </Div>
                    }
                    <Textarea top="Ваша рецензия" name="comment" placeholder="Здесь ваша рецензия..." style={{minHeight: "150px"}} value={this.state.comment} onChange={this.commentChange}/>
                    <Div><MultipleChipAutoCompleteField url={this.context.domain + "/api/tag?autocomplete="}
                                            id="tagList"
                                            label="Теги"
                                            value={this.state.tagList ? this.state.tagList.split(' ').map((item) => {return {name: item}}) : ''}
                                            onChange={(value) => {
                                                this.setState({tagList: value.map(item => item.name).join(' ')})
                                            }}
                                            getOptionLabel={(option) => option.name}
                    /></Div>
                    <Div>
                        <ToggleButtonGroup
                            value={this.state.type}
                            exclusive
                            onChange={(event, newAlignment) => this.setState({type: newAlignment})}
                            aria-label="Тип"
                        >
                            <ToggleButton value="finished" aria-label="Прочитано">
                                Прочитано
                            </ToggleButton>
                            <ToggleButton value="want" aria-label="Хочу прочитать">
                                Хочу прочитать
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Div>
                    <FixedLayout vertical="bottom">
                        <Div style={{display: 'flex'}}>
                            <Button stretched onClick={()=>this.saveReview()}>Сохранить</Button>
                        </Div>
                    </FixedLayout>
                    <input type="hidden" name="id" value={this.state.id}/>
                    <input type="hidden" name="book.id" value={this.state.bookId}/>
                    <input type="hidden" name="type" value={this.state.type}/>
                    <input type="hidden" name="rating" value={this.state.rating}/>
                    <input type="hidden" name="tagList" value={this.state.tagList}/>
                    {this.state.snackbar}
                </FormLayout>
            </Panel>
		);
	}
}

export default ReviewEdit;