import React from 'react';
import {
    Button,
    Div,
    Group,
    Header,
    Panel,
    PanelHeader,
    Placeholder,
    Search,
    CardGrid,
    Card,
    RichCell, PanelHeaderContent, List, Avatar, Cell, Link, PanelHeaderButton, Alert, ScreenSpinner, Snackbar, Text
} from '@vkontakte/vkui';
import {AppSettingsContext} from '../app-settings-context';
import Icon56ServicesOutline from '@vkontakte/icons/dist/56/services_outline';
import Icon56Users3Outline from '@vkontakte/icons/dist/56/users_3_outline';
import Skeleton from "@material-ui/lab/Skeleton";
import Icon24BrowserBack from '@vkontakte/icons/dist/24/browser_back';
import Icon16CancelCircleOutline from '@vkontakte/icons/dist/16/cancel_circle_outline';
import Icon16Done from '@vkontakte/icons/dist/16/done';
import Icon28InfoOutline from '@vkontakte/icons/dist/28/info_outline';

class ClubMembers extends React.Component {
    static contextType = AppSettingsContext;

    constructor(props) {
        super(props);

        this.state = {
            id: props.route.params.id,
            name: null,
            imageUrl: "/club.png",
            canEdit: false,
            creator: {},
            snackbar: null,
            items: null,
            approveList: [],
            search: "",
            user: {fio: "", imageUrl: '/account.png'},
            loading: true,
        }

        this.getItems = this.getItems.bind(this);
        this.onSearchChange = this.onSearchChange.bind(this);
        this.getUserInfo = this.getUserInfo.bind(this);
        this.addMember = this.addMember.bind(this);
        this.removeMember = this.removeMember.bind(this);
        this.showSnackBar = this.showSnackBar.bind(this);
    }

    componentDidMount() {
        this.getItems();
        this.getUserInfo();
    }

    getUserInfo(){
        fetch(this.context.domain + "/api/account?" + window.location.search.substr(1))
            .then((res) => res.json())
            .then(
                (result) => {
                    if (result.status === "SUCCESS"){
                        this.setState({
                            user: result.data,
                        }, () => this.props.functions.newFeedItemsCountChange(result.data.unreadCount));
                    } else {
                        this.props.functions.showMessage(<Div>Некорректный пользователь. Перезапустите приложение.</Div>);
                    }
                    this.props.functions.changePopout(null);
                },
                (error) => {
                    /*TODO: Тут нужна обработка ошибки */
                }
            );
    }

    getItems() {
        if (this.state.id){
            fetch(this.context.domain + "/api/club?id=" + this.state.id + "&" + window.location.search.substr(1))
                .then((res) => res.json())
                .then(
                    (result) => {
                        if (result.status === "SUCCESS"){
                            this.setState({
                                id: result.data.id,
                                name: result.data.name,
                                imageUrl: result.data.imageUrl,
                                canEdit: result.data.canEdit,
                                creator: result.data.creator,
                                items: result.data.memberList,
                                approveList: result.data.approveList,
                                loading: false,
                            });
                        } else {
                            this.props.functions.showMessage(<Div>Некорректный пользователь. Перезапустите приложение.</Div>);
                        }
                        this.props.functions.changePopout(null);
                    },
                    (error) => {
                        /*TODO: Тут нужна обработка ошибки */
                    }
                );
        } else {
            this.props.functions.changePopout(null);
        }
    }

    onSearchChange(e){
        this.setState({ search: e.target.value });
    }

    doResizeImageUrl(image, x, y){
        return image.substring(0, image.lastIndexOf('.')) + '-' + x + 'x' + y + image.substring(image.lastIndexOf('.'));
    }

    addMember(id, fio){
        this.props.functions.changePopout(<Alert
            actions={[{
                title: 'Отмена',
                autoclose: true,
                mode: 'cancel'
            }, {
                title: 'Добавить',
                autoclose: true,
                action: () => {
                    this.props.functions.changePopout(<ScreenSpinner />);
                    const formData = new FormData();
                    var item = {id: this.state.id};
                    formData.append('clubJson', JSON.stringify(item));
                    fetch(this.context.domain + "/api/club?add-member=" + id + "&" + window.location.search.substr(1), {
                        method: 'POST',
                        body: formData
                    }).then((res) => res.json())
                        .then(
                            (result) => {
                                if (result.status === "SUCCESS"){
                                    this.getItems()
                                } else {
                                    this.showSnackBar("Ошибка: " + result.message, true);
                                }
                                this.props.functions.changePopout(null);
                            },
                            (error) => {
                                /*TODO: Тут нужна обработка ошибки */
                                this.props.functions.changePopout(null);
                                this.showSnackBar("Ошибка сервера. Попробуйте перезапустить приложение", true);
                            }
                        )
                },
            }]}
            onClose={() => this.props.functions.changePopout(null)}
        >
            <h2>Подтвердите действие</h2>
            <p>Добавить участника {fio}?</p>
        </Alert>)
    }


    removeMember(id, fio){
        this.props.functions.changePopout(<Alert
            actions={[{
                title: 'Отмена',
                autoclose: true,
                mode: 'cancel'
            }, {
                title: 'Добавить',
                autoclose: true,
                action: () => {
                    this.props.functions.changePopout(<ScreenSpinner />);
                    const formData = new FormData();
                    var item = {id: this.state.id};
                    formData.append('clubJson', JSON.stringify(item));
                    fetch(this.context.domain + "/api/club?remove-member=" + id + "&" + window.location.search.substr(1), {
                        method: 'POST',
                        body: formData
                    }).then((res) => res.json())
                        .then(
                            (result) => {
                                if (result.status === "SUCCESS"){
                                    this.getItems()
                                } else {
                                    this.showSnackBar("Ошибка: " + result.message, true);
                                }
                                this.props.functions.changePopout(null);
                            },
                            (error) => {
                                /*TODO: Тут нужна обработка ошибки */
                                this.props.functions.changePopout(null);
                                this.showSnackBar("Ошибка сервера. Попробуйте перезапустить приложение", true);
                            }
                        )
                },
            }]}
            onClose={() => this.props.functions.changePopout(null)}
        >
            <h2>Подтвердите действие</h2>
            <p>Добавить участника {fio}?</p>
        </Alert>)
    }

    showSnackBar (message, isError = false) {
        if (this.state.snackbar) return;
        this.setState({ snackbar:
                <Snackbar
                    layout="vertical"
                    onClose={() => this.setState({ snackbar: null })}
                    before={isError ? <Avatar size={24} style={{backgroundColor: 'var(--dynamic_red)'}}><Icon16CancelCircleOutline fill="#fff" width={14} height={14} /></Avatar>
                        :<Avatar size={24} style={{backgroundColor: 'var(--accent)'}}><Icon16Done fill="#fff" width={14} height={14} /></Avatar>}
                >
                    {message}
                </Snackbar>
        });
    }

    render() {
        let items = null;
        let itemsCount = this.state.items ? this.state.items.length : 0

        if (itemsCount){
            const search = this.state.search.toLowerCase();
            items = this.state.items
                .filter((item) =>
                    (
                        (item.name && item.name.toLowerCase().indexOf(search) > -1)
                        || (item.surname && item.surname.toLowerCase().indexOf(search) > -1)
                        || (item.patronymic && item.patronymic.toLowerCase().indexOf(search) > -1)
                    )
                )
                .map((item) => {
                    return (
                        <Cell key={item.id} before={ <Avatar size={40} src={this.context.domain + "/files" + this.doResizeImageUrl(item.imageUrl, memberImageWidth, memberImageHeight)} /> }
                              removable={this.state.canEdit}
                              description={item.lastActivityDatetimeStr && "Активность: " + item.lastActivityDatetimeStr}
                              onRemove={() => this.removeMember(item.id, item.fio)}>
                            <Link href={"https://vk.com/id" + item.vkUserId} target="_blank">{item.fio}</Link>
                        </Cell>
                    );
                });

            if (!items || !items.length){
                items = (
                    <Placeholder
                        icon={<Icon56ServicesOutline />}
                        header="Никто не найден"
                    >
                        Поменяйте параметры поиска!
                    </Placeholder>
                );
            } else {
                items = (
                    <React.Fragment>
                        <List>
                            {items}
                        </List>
                        <Div></Div>
                    </React.Fragment>
                )
            }
        } else {
            items = (
                <Placeholder
                    icon={<Icon56Users3Outline />}
                    header="Пока никого нет"
                >
                    Еще нет участников. Поделитесь ссылкой на клуб
                </Placeholder>
            );
        }

        var sceletonWidth = Math.round((document.documentElement.clientWidth || document.body.clientWidth || window.innerWidth) / 5);
        var sceletonBorderRadius = Math.round(sceletonWidth / 20);
        var memberImageWidth = Math.round((document.documentElement.clientWidth || document.body.clientWidth || window.innerWidth) / 10);
        var memberImageHeight = memberImageWidth;

        return (
            <Panel id={this.props.id}>
                <PanelHeader left={<PanelHeaderButton><Icon24BrowserBack onClick={()=>this.props.functions.goToPage('clubs.view', {id: this.state.id})}/></PanelHeaderButton>}>
                    <PanelHeaderContent before={
                            <img style={{ maxWidth: memberImageWidth, borderRadius: "50%" }}
                             src={this.context.domain + "/files" + this.doResizeImageUrl(this.state.imageUrl, memberImageWidth, memberImageHeight)} alt="" />}
                        status={this.state.name}>
                        Участники клуба
                    </PanelHeaderContent>
                </PanelHeader>
                {this.state.approveList.length > 0 &&
                    <Group header={<Header mode="secondary">Заявки на вступление</Header>}>
                        {!this.state.canEdit &&
                        <List>
                            <Cell multiline before={<Icon28InfoOutline />}><Text weight="medium" style={{ marginBottom: 0, color: "gray" }}>Заявки может подтверждать админ клуба (<Link href={"https://vk.com/id" + this.state.creator.vkUserId} target="_blank">{this.state.creator.fio}</Link>)</Text></Cell>
                        </List>
                        }
                        <List>
                        {
                            this.state.approveList.map((item) => {
                                return (
                                    <Cell key={item.id} before={ <Avatar size={40} src={this.context.domain + "/files" + this.doResizeImageUrl(item.imageUrl, memberImageWidth, memberImageHeight)} /> }
                                          description={item.memberRequestDatetimeStr && "Запрос: " + item.memberRequestDatetimeStr}
                                          size="l"
                                          bottomContent={this.state.canEdit &&
                                              <div style={{ display: 'flex' }}>
                                                  <Button size="m" onClick={() => this.addMember(item.id, item.fio)}>Добавить</Button>
                                                  <Button size="m" onClick={() => this.removeMember(item.id, item.fio)} mode="secondary" style={{ marginLeft: 8 }}>Удалить</Button>
                                              </div>
                                          }
                                    > <Link href={"https://vk.com/id" + item.vkUserId} target="_blank">{item.fio}</Link></Cell>
                                );
                            })
                        }
                        </List>
                    </Group>
                }
                <Group header={<Header mode="secondary">Список</Header>}>
                    {
                        itemsCount > 0 &&
                        <Search value={this.state.search} onChange={this.onSearchChange} after={null} placeholder="ФИО"/>
                    }

                    {this.state.loading ?
                        <React.Fragment>
                            <CardGrid>
                                {Array(10).fill(<Card size="l" mode="shadow">
                                    <RichCell
                                        multiline
                                        before={<Div><Skeleton variant="rect" width={sceletonWidth} height={sceletonWidth} style={{borderRadius: sceletonBorderRadius}} /></Div>}
                                        text={<Skeleton variant="text" width="70%"/>}
                                        caption={<Skeleton variant="text" width="60%"/>}
                                        after={<Div style={{color: "white", fontSize: 10}}>4</Div>}
                                        style={{padding: 0}}
                                    >
                                        <Skeleton variant="text" width="90%"/>
                                    </RichCell>
                                </Card>)}
                            </CardGrid>
                            <Div></Div>
                        </React.Fragment>
                        :
                        items
                    }
                </Group>
                {this.state.snackbar}
            </Panel>
        );
    }
}

export default ClubMembers;