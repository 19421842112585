import React from 'react';
import {
    Avatar,
    Button,
    Cell,
    Div,
    Group,
    List,
    Panel,
    CardGrid,
    PanelHeader,
    Placeholder,
    Search,
    Tabs,
    TabsItem, Card, RichCell, Text
} from '@vkontakte/vkui';
import ReviewListItem from '../components/ReviewListItem';
import bridge from '@vkontakte/vk-bridge';
import Icon24Add from '@vkontakte/icons/dist/24/add';
import {AppSettingsContext} from '../app-settings-context';
import Icon56LikeOutline from '@vkontakte/icons/dist/56/like_outline';
import Icon56ServicesOutline from '@vkontakte/icons/dist/56/services_outline';
import Typography from "@material-ui/core/Typography";
import Skeleton from "@material-ui/lab/Skeleton";

class ReviewList extends React.Component {
    static contextType = AppSettingsContext;

	constructor(props) {
        super(props);
        
        this.state = {
            items: null,
            search: "",
            user: {fio: "", imageUrl: '/account.png'},
            activeType: this.props.activeReviewType,
            loading: true,
        }

        this.getItems = this.getItems.bind(this);
        this.handleAddToHomeScreenClick = this.handleAddToHomeScreenClick.bind(this);
        this.onSearchChange = this.onSearchChange.bind(this);
        this.getUserInfo = this.getUserInfo.bind(this);
    }
    
    componentDidMount() {
        this.getItems(); 
        this.getUserInfo();
    }

    getUserInfo(){
        fetch(this.context.domain + "/api/account?" + window.location.search.substr(1))
            .then((res) => res.json())
            .then(
                (result) => {
                    if (result.status === "SUCCESS"){
                        this.setState({
                            user: result.data,
                        }, () => this.props.functions.newFeedItemsCountChange(result.data.unreadCount));
                    } else {
                        this.props.functions.showMessage(<Div>Некорректный пользователь. Перезапустите приложение.</Div>);
                    }
                    this.props.functions.changePopout(null);
                },
                (error) => {
                    /*TODO: Тут нужна обработка ошибки */
                }
            );
    }

    getItems() {
        fetch(this.context.domain + "/api/review?list&" + window.location.search.substr(1))
        .then((res) => res.json())
        .then(
            (result) => {
                if (result.status === "SUCCESS"){
                    this.setState({
                        items: result.data,
                        loading: false,
                    });
                } else {
                    this.props.functions.showMessage(<Div>Некорректный пользователь. Перезапустите приложение.</Div>);
                }
                this.props.functions.changePopout(null);
            },
            (error) => {
                /*TODO: Тут нужна обработка ошибки */
            }
        ); 
    }

    handleAddToHomeScreenClick(){
        bridge.send("VKWebAppAddToHomeScreen").then(data => {
          /*TODO: Нужно что-то делать, если все хорошо? */
        })
        .catch(error => {
          /*TODO: Тут нужна обработка ошибки */
        });
    }

    onSearchChange(e){
        this.setState({ search: e.target.value });
    }

    doResizeImageUrl(image, x, y){
        return image.substring(0, image.lastIndexOf('.')) + '-' + x + 'x' + y + image.substring(image.lastIndexOf('.'));
    }

	render() {
        let items = null;

        if (this.state.items){
            items = this.state.items
                .filter((item) => (item.type === this.state.activeType))
        }

        let labels ={}
        if (this.state.activeType === 'finished') {
            labels.readLabel ='Прочитано книг: '
            labels.emptyList = 'Прочитайте книгу и добавьте ее сюда!';
        } else {
            labels.readLabel ='Хочу прочитать книг: '
            labels.emptyList = 'Добавьте сюда книгу, которую хотите прочитать!';
        }

        let itemsCount = items ? items.length : 0

        if (itemsCount){
            const search = this.state.search.toLowerCase();
            items = items
                .filter((item) => 
                        (
                            (item.book.name && item.book.name.toLowerCase().indexOf(search) > -1) 
                                || (item.book.authorsString && item.book.authorsString.toLowerCase().indexOf(search) > -1) 
                                || (item.tagList && item.tagList.toLowerCase().indexOf(search) > -1) 
                        )
                    )


            if (!items || !items.length){
                items = (
                    <Placeholder
                        icon={<Icon56ServicesOutline />}
                        header="Ничего не найдено"
                        action={<Button size="l" mode="secondary" stretched style={{ marginRight: 8 }} before={<Icon24Add/>} onClick={() => this.props.functions.showReviewEdit(0, this.state.activeType)}>Добавить книгу</Button>}
                    >
                        Поменяйте параметры поиска!
                    </Placeholder>
                );
            } else {
                let year = items[0].readDateStr ? items[0].readDateStr.split('.')[2] : 'Не указано'
                let result = []
                let block = []
                let that = this
                items.forEach(function(item, index, arr) {
                   // console.log(index, item.readDateStr , year, item.readDateStr != year)
                    let itemYear = item.readDateStr ? item.readDateStr.split('.')[2] : 'Не указано'
                    if (index >= 0 && itemYear != year){
                       result.push(
                           <React.Fragment key={index}>
                               <Div>
                                   <Typography variant="h6" display="block" gutterBottom style={{color: '#818c99'}}>
                                       {year}
                                   </Typography>
                               </Div>
                               <CardGrid>{block}</CardGrid>
                           </React.Fragment>
                       )
                       year = itemYear
                       block = []
                   }
                   block.push(<ReviewListItem key={item.id} data={item} onClick={() => that.props.functions.showReview(item.id, item.type)}/>)
                })
                result.push(
                    <React.Fragment key={items.length}>
                        <Div>
                            <Typography variant="h6" display="block" gutterBottom style={{color: '#818c99'}}>
                                {year}
                            </Typography>
                        </Div>
                        <CardGrid>{block}</CardGrid>
                    </React.Fragment>
                )
                result.push(<Div key="-1"></Div>)
                items = result
            }
        } else {
            items = (
                <Placeholder
                    icon={<Icon56LikeOutline />}
                    header="Пока ничего нет"
                    action={<Button size="l" mode="secondary" stretched style={{ marginRight: 8 }} before={<Icon24Add/>} onClick={() => this.props.functions.showReviewEdit(0, this.state.activeType)}>Добавить книгу</Button>}
                >
                    {labels.emptyList}
                </Placeholder>
            );
        }

        var imageWidth = Math.round((document.documentElement.clientWidth || document.body.clientWidth || window.innerWidth) / 10);
        var imageHeight = imageWidth;

        var sceletonWidth = Math.round((document.documentElement.clientWidth || document.body.clientWidth || window.innerWidth) / 5);
        var sceletonBorderRadius = Math.round(sceletonWidth / 20);

		return (
            <Panel id={this.props.id}>
                <PanelHeader>
                    Прочитано
                </PanelHeader>
                <Tabs>
                    <TabsItem
                        onClick={() => {this.setState({activeType: 'finished'})}}
                        selected={this.state.activeType === 'finished'}
                    >
                        Прочитано
                    </TabsItem>
                    <TabsItem
                        onClick={() => {this.setState({activeType: 'want'})}}
                        selected={this.state.activeType === 'want'}
                    >
                        Хочу прочитать
                    </TabsItem>
                </Tabs>
                <Group>
                    <List>
                        <Cell description={labels.readLabel + itemsCount} before={ <Avatar size={imageWidth} src={this.context.domain + "/files" + this.doResizeImageUrl(this.state.user.imageUrl, imageWidth, imageHeight)} /> }>{this.state.user.fio}</Cell>
                    </List>
                    <Div style={{display: 'flex'}}>
                        <Button size="l" stretched style={{ marginRight: 8 }} before={<Icon24Add/>} onClick={() => this.props.functions.showReviewEdit(0, this.state.activeType)}>Добавить книгу</Button>
                        <Button size="l" stretched mode="secondary" onClick={this.handleAddToHomeScreenClick}>Ярлык</Button>
                    </Div>
                </Group>
                <Group>
                    {
                        itemsCount > 0 &&
                        <Search value={this.state.search} onChange={this.onSearchChange} after={null} placeholder="Название, автор, тег"/>
                    }

                    {this.state.loading ?
                        <React.Fragment>
                            <CardGrid>
                                {Array(5).fill(<Card size="l" mode="shadow">
                                    <RichCell
                                        multiline
                                        before={<Div><Skeleton variant="rect" width={sceletonWidth} height={sceletonWidth*3/2} style={{borderRadius: sceletonBorderRadius}} /></Div>}
                                        text={<Skeleton variant="text" width="70%"/>}
                                        caption={<Skeleton variant="text" width="60%"/>}
                                        after={<Div style={{color: "white", fontSize: 10}}>4</Div>}
                                        style={{padding: 0}}
                                    >
                                        <Skeleton variant="text" width="90%"/>
                                    </RichCell>
                                </Card>)}
                            </CardGrid>
                            <Div></Div>
                        </React.Fragment>
                        :
                        items
                    }

                </Group>
            </Panel>
		);
	}
}

export default ReviewList;