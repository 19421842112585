import React from 'react';
import { Panel, PanelHeader, Group, Div, PanelHeaderButton } from '@vkontakte/vkui';
import Icon24BrowserBack from '@vkontakte/icons/dist/24/browser_back';

function Message(props){
	return (
		<Panel id={props.id}>
			<PanelHeader left={<PanelHeaderButton><Icon24BrowserBack onClick={() => this.props.functions.showReviewList(props.activeReviewType)}/></PanelHeaderButton>}>
				Сообщение
			</PanelHeader>
			<Group>
				<Div>{props.message}</Div>
			</Group>
		</Panel>
	);
}

export default Message;