// *https://www.registers.service.gov.uk/registers/country/use-the-api*
import fetch from 'cross-fetch';
import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';

export default function AutoCompleteField(props) {
    const [open, setOpen] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    let loading = false;

    function inputChange(event, value, reason){
        if (!value){
            if (reason==='input') {
                props.onChange('')
            }
            setOptions([]);
            return
        }

        if (loading || reason!=='input') {
            return;
        }

        loading = true;

        (async () => {
            const response = await fetch(props.url + value);
            const answer = await response.json();
            setOptions(answer.data);
            loading = false;
        })();

        props.onChange(value)
    }

    function onChange(event, valueObject, reason){
        if (loading || (reason !== 'select-option' && reason !== 'clear')) {
            return;
        }
        
        props.onChange(null, valueObject)
    }

    React.useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);

    return (
        <Autocomplete
            id={props.id}
            freeSolo
            fullWidth
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => {
                setOpen(false);
            }}
            onInputChange={inputChange}
            onChange={onChange}
            getOptionSelected={(option, value) => option.id === value.id}
            getOptionLabel={props.getOptionLabel}
            options={options}
            loading={loading}
            //defaultValue={{name: props.value}}
            inputValue={props.value ? props.value : ''}
            renderInput={(params) => (
                <TextField
                    {...params}
                    name={props.name}
                    label={props.label}
                    variant="outlined"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                    inputProps={{
                        ...params.inputProps,
                        'field-name': props['field-name'],
                    }}
                />
            )}
            renderOption={(option, { inputValue }) => {
                const matches = match(props.getOptionLabel(option), inputValue);
                const parts = parse(props.getOptionLabel(option), matches);

                return (
                    <div>
                        {parts.map((part, index) => (
                            <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                {part.text}
                            </span>
                        ))}
                    </div>
                );
            }}
        />
    );
}
