import fetch from 'cross-fetch';
import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import Chip from "@material-ui/core/Chip";

export default function MultipleChipAutoCompleteField(props) {
    const [open, setOpen] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    let curValue = props.value ? props.value : []
    let loading = false;

    function inputChange(event, value, reason){
        if (!value || loading || reason !== 'input' || !curValue.length) {
            return;
        }

        loading = true;

        (async () => {
            const response = await fetch(props.url + value);
            const answer = await response.json();
            setOptions(answer.data);
            loading = false;
        })();
    }

    function onChange(event, valueObject, reason){
        if (reason !== 'remove-option' && event.target.value) {
            curValue.push({name: event.target.value})
        } else {
            curValue = valueObject.slice()
        }
        props.onChange(curValue)
    }

    React.useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);

    return (
        <Autocomplete
            multiple
            id={props.id}
            freeSolo
            fullWidth
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => {
                setOpen(false);
            }}
            onInputChange={inputChange}
            onChange={onChange}
            getOptionLabel={props.getOptionLabel}
            options={options}
            loading={loading}
            //defaultValue={{name: props.value}}
            //inputValue={props.value ? props.value : ''}
            value={curValue}
            //defaultValue={['1', 2]}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                    <Chip variant="outlined" label={option.name ? option.name : option} {...getTagProps({ index })} />
                ))
            }
            renderInput={(params) => (
                <TextField
                    {...params}
                    name={props.name}
                    label={props.label}
                    variant="outlined"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                    inputProps={{
                        ...params.inputProps,
                        'field-name': props['field-name'],
                    }}
                />
            )}
            renderOption={(option, { inputValue }) => {
                const matches = match(props.getOptionLabel(option), inputValue);
                const parts = parse(props.getOptionLabel(option), matches);

                return (
                    <div>
                        {parts.map((part, index) => (
                            <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                {part.text}
                            </span>
                        ))}
                    </div>
                );
            }}
        />
    );
}

/*
<Autocomplete
            multiple
            id={props.id}
            freeSolo
            fullWidth
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => {
                setOpen(false);
            }}
            onInputChange={inputChange}
            onChange={onChange}
            getOptionLabel={props.getOptionLabel}
            options={options}
            loading={loading}
            //defaultValue={{name: props.value}}
            //inputValue={props.value ? props.value : ''}
            defaultValue={props.value}
            //defaultValue={['1', 2]}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                    <Chip variant="outlined" label={option.name ? option.name : option} {...getTagProps({ index })} />
                ))
            }
            renderInput={(params) => (
                <TextField
                    {...params}
                    name={props.name}
                    label={props.label}
                    variant="outlined"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                    inputProps={{
                        ...params.inputProps,
                        'field-name': props['field-name'],
                    }}
                />
            )}
            renderOption={(option, { inputValue }) => {
                const matches = match(props.getOptionLabel(option), inputValue);
                const parts = parse(props.getOptionLabel(option), matches);

                return (
                    <div>
                        {parts.map((part, index) => (
                            <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                {part.text}
                            </span>
                        ))}
                    </div>
                );
            }}
        />
 */