import React from 'react';
import {
    Panel, PanelHeaderButton, PanelHeader, Div, Avatar, Title
    , Text, Snackbar, Button, PanelHeaderContent, Alert, ScreenSpinner
    , Placeholder, Group, Header, Search, CardGrid, Card
} from '@vkontakte/vkui';
import Icon24BrowserBack from '@vkontakte/icons/dist/24/browser_back';
import {AppSettingsContext} from '../app-settings-context';
import ReviewListItem from '../components/ReviewListItem';
import bridge from '@vkontakte/vk-bridge';
import Icon16Done from '@vkontakte/icons/dist/16/done';
import Icon16CancelCircleOutline from '@vkontakte/icons/dist/16/cancel_circle_outline';
import Icon56InboxOutline from '@vkontakte/icons/dist/56/inbox_outline';
import Icon56ServicesOutline from '@vkontakte/icons/dist/56/services_outline';

class Collection extends React.Component {
    static contextType = AppSettingsContext;

	constructor(props) {
        super(props);

        this.state ={
            id: props.route.params.id,
            imageUrl: "/collection.png",
            isLocalImage: false,
            name: null,
            addedDateTimeStr: "",
            comment: "",
            tagList: null,
            reviewList: null,
            search: "",
            canEdit: false,
            authorFio: "",
            snackbar: null,
        }

        this.getCollection = this.getCollection.bind(this);
        this.showSnackBar = this.showSnackBar.bind(this);
        this.deleteCollection = this.deleteCollection.bind(this);
        this.share = this.share.bind(this);
	}

    componentDidMount() {
        this.getCollection();
    }

    getCollection() {
        if (this.state.id){
            fetch(this.context.domain + "/api/collection?id=" + this.state.id + "&" + window.location.search.substr(1))
            .then((res) => res.json())
            .then(
                (result) => {
                    if (result.status === "SUCCESS"){
                        if (result.data){
                            this.setState({
                                id: result.data.id,
                                name: result.data.name,
                                imageUrl: result.data.imageUrl,
                                isLocalImage: false,
                                addedDateTimeStr: result.data.addedDateTimeStr,
                                comment: result.data.comment,
                                public: result.data.public,
                                tagList: result.data.tagList,
                                reviewList: result.data.reviewList,
                                canEdit: result.data.canEdit,
                                authorFio: result.data.user.fio,
                            });
                        } else {
                            this.setState({
                                id: 0,
                            });
                        }
                    } else {
                        this.showSnackBar("Ошибка: " + result.message, true);
                    }
                    this.props.functions.changePopout(null);
                },
                (error) => {
                    /*TODO: Тут нужна обработка ошибки */
                    this.props.functions.changePopout(null);
                    this.showSnackBar("Ошибка сервера. Попробуйте перезапустить приложение", true);
                }
            ); 
        } else {
            this.props.functions.changePopout(null);
        }
    }

    showSnackBar (message, isError = false) {
        if (this.state.snackbar) return;
        this.setState({ snackbar:
          <Snackbar
            layout="vertical"
            onClose={() => this.setState({ snackbar: null })}
            before={isError ? <Avatar size={24} style={{backgroundColor: 'var(--dynamic_red)'}}><Icon16CancelCircleOutline fill="#fff" width={14} height={14} /></Avatar>
                        :<Avatar size={24} style={{backgroundColor: 'var(--accent)'}}><Icon16Done fill="#fff" width={14} height={14} /></Avatar>}
          >
            {message}
          </Snackbar>
        });
    }

    doResizeImageUrl(image, x, y){
        return image.substring(0, image.lastIndexOf('.')) + '-' + x + 'x' + y + image.substring(image.lastIndexOf('.'));
    }

    deleteCollection(){
        this.props.functions.changePopout(<Alert
            actions={[{
              title: 'Отмена',
              autoclose: true,
              mode: 'cancel'
            }, {
              title: 'Удалить',
              autoclose: true,
              action: () => {
                this.props.functions.changePopout(<ScreenSpinner />);
                const formData = new FormData();
                var item = {id: this.state.id};
                formData.append('collectionJson', JSON.stringify(item));
                fetch(this.context.domain + "/api/collection?delete&" + window.location.search.substr(1), {
                    method: 'POST',
                    body: formData
                }).then((res) => res.json())
                .then(
                    (result) => {
                        if (result.status === "SUCCESS"){
                            this.props.functions.showCollectionList()
                        } else {
                            this.showSnackBar("Ошибка: " + result.message, true);
                        }
                        this.props.functions.changePopout(null);
                    },
                    (error) => {
                        /*TODO: Тут нужна обработка ошибки */
                        this.props.functions.changePopout(null);
                        this.showSnackBar("Ошибка сервера. Попробуйте перезапустить приложение", true);
                    }
                )
              },
            }]}
            onClose={() => this.props.functions.changePopout(null)}
          >
            <h2>Подтвердите действие</h2>
            <p>Удаленную подборку нельзя будет восстановить. Вы уверены, что хотите удалить?</p>
          </Alert>)
    }

    share(){
	    var url = 'https://vk.com/app7445753_20049297#collection_id=' + this.state.id
        if (navigator.share) {
            alert(2)
            navigator.share({
                title: 'Поделиться',
                text: 'Вы можете поделиться вашей подборкой!',
                url: url
            }).then(() => {
                //console.log('Thanks for sharing!');
            })
                .catch(console.error);
        } else {
            bridge.send("VKWebAppCopyText", {text: url});

            if (navigator.clipboard){
                navigator.clipboard.writeText(url)
            }

            alert('Ссылка скопирована в буфер обмена')
        }
    }

	render() {
        var imageWidth = Math.round((document.documentElement.clientWidth || document.body.clientWidth || window.innerWidth)*0.82);
        var imageHeight = Math.round(imageWidth / 2 * 3);
        var margin = Math.round((document.documentElement.clientWidth || document.body.clientWidth || window.innerWidth)*0.05);
        var borderRadius = Math.round(imageWidth / 20);

        let items = null;
        var reviewsCount = this.state.reviewList ? this.state.reviewList.length : 0

        if (reviewsCount){
            const search = this.state.search.toLowerCase();
            items = this.state.reviewList
                .filter((item) => 
                        (
                            (item.book.name && item.book.name.toLowerCase().indexOf(search) > -1) 
                                || (item.book.authorsString && item.book.authorsString.toLowerCase().indexOf(search) > -1) 
                                || (item.tagList && item.tagList.toLowerCase().indexOf(search) > -1) 
                            )
                    )
                .map((item) => {
                    return (
                        <ReviewListItem key={item.id} data={item} onClick={() => this.props.functions.showReview(item.id, item.type)}/>
                    );
                });

                if (!items || !items.length){
                    items = (
                        <Placeholder
                            icon={<Icon56ServicesOutline />}
                            header="Ничего не найдено"
                        >
                            Поменяйте параметры поиска!
                        </Placeholder>
                    );
                } else {
                    items = (
                        <CardGrid>
                            {items}
                        </CardGrid>
                    )
                }
        } else {
            items = (
                <Placeholder
                    icon={<Icon56InboxOutline />}
                    header="Пока ничего нет"
                >
                    В подборке еще нет книг
                </Placeholder>
            );
        }

		return (
			<Panel id={this.props.id}>
                <PanelHeader left={<PanelHeaderButton><Icon24BrowserBack onClick={this.props.functions.showCollectionList}/></PanelHeaderButton>}>
                    <PanelHeaderContent
                        status={this.state.id ? "Создана: " + this.state.addedDateTimeStr : "Подборка не найдена"}
                        //before={<img style={{ marginLeft: margin, maxWidth: "36px", maxHeight: "36px" }} src={this.state.isLocalImage ? this.state.imageUrl : this.context.domain + "/files" + this.doResizeImageUrl(this.state.imageUrl, 36, 36)} />}
                    >
                        {this.state.name}
                    </PanelHeaderContent>
                </PanelHeader>
                {this.state.id ?
                    <React.Fragment>
                        <Div style={{display: 'flex'}}>
                            <Text>Автор: <b>{this.state.authorFio}</b></Text>
                        </Div>
                        <Div style={{display: 'flex'}}>
                            <Button stretched onClick={this.share}>Поделиться</Button>
                        </Div>
                        <CardGrid>
                            <Card size="l" mode="shadow">
                                <img style={{margin: margin, maxWidth: imageWidth, borderRadius: borderRadius}}
                                     src={this.state.isLocalImage ? this.state.imageUrl : this.context.domain + "/files" + this.doResizeImageUrl(this.state.imageUrl, imageWidth, imageHeight)}
                                     alt=""/>
                            </Card>
                        </CardGrid>
                        <Div>
                            <Title level="2" weight="heavy">{this.state.name}</Title>
                        </Div>
                        {this.state.comment &&
                        <Div>
                            <Text weight="regular"
                                  style={{marginBottom: 16, whiteSpace: "pre-wrap"}}>{this.state.comment}</Text>
                        </Div>
                        }
                        {this.state.tagList &&
                        <Div>
                            <Text weight="regular"
                                  style={{marginBottom: 8, color: "#3f8ae0"}}>{this.state.tagList}</Text>
                        </Div>
                        }
                        <Group header={<Header mode="secondary">Книги</Header>}>
                            {
                                reviewsCount > 0 &&
                                <Search value={this.state.search} onChange={this.onSearchChange}
                                        placeholder="Название, автор, тег"/>
                            }
                            {items}
                        </Group>
                        {this.state.canEdit &&
                        <Div style={{display: 'flex'}}>
                            <Button stretched style={{"marginRight": 8}}
                                    onClick={() => this.props.functions.showCollectionEdit(this.state.id)}>Редактировать</Button>
                            <Button mode="secondary" stretched onClick={this.deleteCollection}>Удалить</Button>
                        </Div>
                        }
                    </React.Fragment>
                    :
                    <React.Fragment>
                        <Placeholder
                            icon={<Icon56ServicesOutline/>}
                            header="Подборка не найдена"
                        >
                            Подборки не существует или автор скрыл ее из публичного доступа
                        </Placeholder>
                    </React.Fragment>
                }
                {this.state.snackbar}
            </Panel>
		);
	}
}

export default Collection;