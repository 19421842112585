import React from 'react';
import {Div, Text, Card, Avatar, Cell} from '@vkontakte/vkui';
import Typography from "@material-ui/core/Typography";

import {AppSettingsContext} from '../app-settings-context';

class FeedListItem extends React.Component {
    static contextType = AppSettingsContext;

    doResizeImageUrl(image, x, y){
        return image.substring(0, image.lastIndexOf('.')) + '-' + x + 'x' + y + image.substring(image.lastIndexOf('.'));
    }

    render() {
        var imageWidth = Math.round((document.documentElement.clientWidth || document.body.clientWidth || window.innerWidth)*0.82);
        var imageHeight = Math.round(imageWidth / 2 * 3);
        var margin = Math.round((document.documentElement.clientWidth || document.body.clientWidth || window.innerWidth)*0.05);
        var borderRadius = Math.round(imageWidth / 20);

        var avatarWidth = Math.round((document.documentElement.clientWidth || document.body.clientWidth || window.innerWidth) / 15);
        var avatarHeight = avatarWidth;

        return (
            <Card size="l" mode="shadow" onClick={this.props.onClick}>
                <Cell description={this.props.data.addedDateTimeStr} before={ <Avatar size={avatarWidth} src={this.context.domain + "/files" + this.doResizeImageUrl(this.props.data.review.user.imageUrl, avatarWidth, avatarHeight)} /> }>{this.props.data.review.user.fio}</Cell>
                <img style={{ margin: margin, maxWidth: imageWidth, borderRadius: borderRadius, boxShadow: '0 15px 10px rgba(0, 0, 0, 0.5)' }} src={this.context.domain + "/files" + this.doResizeImageUrl(this.props.data.review.imageUrl, imageWidth, imageHeight)} alt=""/>
                <Div>
                    <b>{this.props.data.review.book.name}</b>
                    <Text weight="regular" style={{ color: "gray"}}>{this.props.data.review.book.authorsString}</Text>
                    <br/>
                    <Typography variant="body2" gutterBottom>
                        {this.props.data.review.shortText}
                    </Typography>
                    <Text weight="regular" style={{ color: "#3f8ae0", fontSize: 13, marginTop: 5}}>{this.props.data.review.tagList}</Text>
                </Div>
            </Card>
        );
    }
  }

  export default FeedListItem;
