import React from 'react';
import {Button, Div, RichCell, Text, Card} from '@vkontakte/vkui';

import {AppSettingsContext} from '../app-settings-context';

class ReviewListItem extends React.Component {
    static contextType = AppSettingsContext;

    doResizeImageUrl(image, x, y){
        return image.substring(0, image.lastIndexOf('.')) + '-' + x + 'x' + y + image.substring(image.lastIndexOf('.'));
    }

    render() {
        var imageWidth = Math.round((document.documentElement.clientWidth || document.body.clientWidth || window.innerWidth) / 5);
        var imageHeight = Math.round(imageWidth / 2 * 3);
        var borderRadius = Math.round(imageWidth / 20);

        return (
            <Card size="l" mode="shadow">
                <RichCell
                    multiline
                    before={<Div><img style={{ maxWidth: imageWidth, maxHeight: imageWidth*2, borderRadius: borderRadius }} src={this.context.domain + "/files" + this.doResizeImageUrl(this.props.data.imageUrl, imageWidth, imageHeight)} alt="" /></Div>}
                    text={<Text weight="regular" style={{ color: "gray"}}>{this.props.data.book.authorsString}</Text>}
                    caption={<Text weight="regular" style={{ color: "var(--text_secondary)", fontSize: 13, marginTop: 5}}>{this.props.data.type=='finished' && this.props.data.readDateStr ? 'прочитано ' + this.props.data.readDateStr : ''}<br/>{this.props.data.tagList}</Text>}
                    after={<Div style={{background: "left 8px top 9px no-repeat url(" + this.context.domain + "/files/star.png)", color: "white", fontSize: 10}}>{this.props.data.rating}</Div>}
                    onClick={this.props.onClick}
                    actions = {this.props.onDelete &&
                    <React.Fragment>
                        <Button mode="secondary" onClick={() => this.props.onDelete(this.props.data.id)}>Удалить</Button>
                    </React.Fragment>
                    }
                    style={{padding: 0}}
                >
                    <b>{this.props.data.book.name}</b>
                </RichCell>
            </Card>
  /*      <Banner
            before={<Avatar size={96} mode="image" src={this.context.domain + "/files" + this.props.data.resizedImageUrl} />}
            header={this.props.data.book.name}
            subheader={
                <React.Fragment>
                   <b>{this.props.data.book.authorsString}</b>
                </React.Fragment>
                }
            actions={"прочитано " + this.props.data.addedDateTimeStr}
        />*/
        );
    }
  }

  export default ReviewListItem;

/*
<RichCell
                multiline
                before={<Div><img style={{ maxWidth: imageWidth, maxHeight: imageWidth*2, borderRadius: borderRadius }} src={this.context.domain + "/files" + this.doResizeImageUrl(this.props.data.imageUrl, imageWidth, imageHeight)} /></Div>}
                text={<Text weight="regular" style={{ color: "gray"}}>{this.props.data.book.authorsString}</Text>}
                caption={<Text weight="regular" style={{ color: "var(--text_secondary)", fontSize: 13, marginTop: 5}}>прочитано {this.props.data.addedDateTimeStr}<br/>{this.props.data.tagList}</Text>}
                after={<Div style={{background: "left 8px top 13px no-repeat url(" + this.context.domain + "/files/star.png)", color: "white", fontSize: 10}}>{this.props.data.rating}</Div>}
                onClick={() => this.props.onClick(this.props.data.id)}
                actions = {this.props.onDelete &&
                    <React.Fragment>
                        <Button mode="secondary" onClick={() => this.props.onDelete(this.props.data.id)}>Удалить</Button>
                    </React.Fragment>
                }
                >
                <b>{this.props.data.book.name}</b>
            </RichCell>
 */